import { mapValues } from "lodash"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ArticleGroupId } from "./ArticleGroup"
import {
  KeysGroupBaseData,
  KeysSubGroupContent,
  KeysSubGroupContentEntity,
  KeysSubGroupId,
} from "./KeysSubGroup"
import { MainGroupId } from "./MainGroup"

export type KeysMainGroupId = string

export type KeysMainGroupContent = KeysGroupBaseData & {
  keysMainGroupId: KeysMainGroupId
  orderIndex: number
  keysSubGroups: KeysSubGroupContent[]
}

export type SpecificKeysMainGroupContent = Omit<
  KeysMainGroupContent,
  "keysSubGroups"
> & { keysSubGroups: Record<KeysSubGroupId, KeysSubGroupContent> }

export type KeysMainGroupData = KeysGroupBaseData & {
  mainGroupId: MainGroupId | undefined
  articleGroupId: ArticleGroupId | undefined
}

export type KeysMainGroupDataWithId = KeysGroupBaseData &
  Pick<KeysMainGroupContent, "keysMainGroupId">

export const KeysMainGroupContentEntity =
  createEntityFor<SpecificKeysMainGroupContent>()
    .withSchema(
      z.object({
        keysMainGroupId: z.string(),
        name: z.string(),
        color: z.string(),
        orderIndex: z.number(),
        keysSubGroups: z.record(KeysSubGroupContentEntity.schema),
      }),
    )
    .serialize(data => ({
      ...data,
      keysSubGroups: mapValues(
        data.keysSubGroups,
        KeysSubGroupContentEntity.serialize,
      ),
    }))
    .deserialize(json => ({
      ...json,
      keysSubGroups: mapValues(
        json.keysSubGroups,
        KeysSubGroupContentEntity.deserialize,
      ),
    }))
