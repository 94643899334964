import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type GPtomTransactionResponse = {
  amount?: number
  tipAmount?: number
  timestamp?: string
  terminalId?: string
  merchantId?: string
  cardNumber?: string
  cardIssuer?: string
  batchNumber?: string
  europayAppId?: string
  currencyCode?: string
  transactionId?: string
  receiptNumber?: string
  sequenceNumber?: string
  responseMessage?: string
  transactionType?: string
  authorizationCode?: string
}

export type GPtomReceiptData = Omit<
  GPtomTransactionResponse,
  "responseMessage" | "merchantId"
>

export const GPtomReceiptDataEntity = createEntityFor<GPtomReceiptData>()
  .withSchema(
    z.object({
      amount: z.onumber(),
      tipAmount: z.onumber(),
      timestamp: z.ostring(),
      terminalId: z.ostring(),
      cardNumber: z.ostring(),
      cardIssuer: z.ostring(),
      currencyCode: z.ostring(),
      receiptNumber: z.ostring(),
      transactionId: z.ostring(),
      sequenceNumber: z.ostring(),
      transactionType: z.ostring(),
      authorizationCode: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
