import React, { Fragment, ReactNode } from "react"

import { RowSeparator } from "../display/Separator"
import { Box } from "../layout/Box"
import { Column, Row, RowProps } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { ResponsiveSpacing } from "../types"

// External relevant types

export type TableColumn<T> = {
  header: ReactNode
  renderCell: (row: T) => ReactNode
  flex?: number
}

export type TableProps<T> = {
  rows: T[]
  columns: TableColumn<T>[]

  // Whether an end separator (after the data rows) should be shown or not.
  showEndSeparator?: boolean

  // If set to true, paints the header separator in a bolder/amplified color.
  headerSeparatorBold?: boolean

  // Flag to include a line separator between each data row.
  showSeparatorBetweenRows?: boolean

  // Vertical gap that is inserted between
  // the header + separator and data rows AND
  // between the end separator (if there is one shown) and data rows.
  gapTopBottom?: ResponsiveSpacing

  // Vertical gap that is inserted between data rows
  // (if `showSeparatorBetweenRows` is true,
  //  it will be applied before and after the separator).
  gapBetweenRows?: ResponsiveSpacing

  // Horizontal gap that is inserted between header and data columns.
  gapBetweenColumns?: ResponsiveSpacing
}

// Internal relevant types

type HeaderRowProps<T> = Pick<TableProps<T>, "columns" | "gapBetweenColumns">
type DataRowsProps<T> = HeaderRowProps<T> &
  DataRowSeparatorProps<T> &
  Pick<TableProps<T>, "rows" | "showSeparatorBetweenRows">
type DataRowProps<T> = HeaderRowProps<T> & { row: T }
type TableRowProps = Pick<RowProps, "gap"> & { children: ReactNode }

type HeaderSeparatorProps<T> = EndSeparatorProps<T> &
  Pick<TableProps<T>, "headerSeparatorBold">
type DataRowSeparatorProps<T> = Pick<TableProps<T>, "gapBetweenRows">
type EndSeparatorProps<T> = Pick<TableProps<T>, "gapTopBottom">

// A simple table component with arbitrary ReactNodes as cells.
export function Table<T>({
  rows,
  columns,
  gapTopBottom,
  gapBetweenRows,
  gapBetweenColumns,
  showEndSeparator = false,
  headerSeparatorBold = false,
  showSeparatorBetweenRows = false,
}: TableProps<T>) {
  return (
    <Column gap="XXXXS">
      <HeaderRow columns={columns} gapBetweenColumns={gapBetweenColumns} />
      <HeaderSeparator
        gapTopBottom={gapTopBottom}
        headerSeparatorBold={headerSeparatorBold}
      />
      <DataRows
        rows={rows}
        columns={columns}
        gapBetweenRows={gapBetweenRows}
        gapBetweenColumns={gapBetweenColumns}
        showSeparatorBetweenRows={showSeparatorBetweenRows}
      />
      {showEndSeparator && <EndSeparator gapTopBottom={gapTopBottom} />}
    </Column>
  )
}

function HeaderRow<T>({ columns, gapBetweenColumns }: HeaderRowProps<T>) {
  return (
    <TableRow gap={gapBetweenColumns}>
      {columns.map(({ flex, header }, index) => (
        <Box key={`headerRowColumn_${index}`} flex={flex ?? 1}>
          {header}
        </Box>
      ))}
    </TableRow>
  )
}

function DataRows<T>({
  rows,
  columns,
  gapBetweenRows,
  gapBetweenColumns,
  showSeparatorBetweenRows,
}: DataRowsProps<T>) {
  return (
    <Column gap={!showSeparatorBetweenRows ? gapBetweenRows : undefined}>
      {rows.map((row, index) => (
        <Fragment key={`dataRow_${index}`}>
          {showSeparatorBetweenRows && index > 0 && (
            <DataRowSeparator gapBetweenRows={gapBetweenRows} />
          )}
          <DataRow
            row={row}
            columns={columns}
            gapBetweenColumns={gapBetweenColumns}
          />
        </Fragment>
      ))}
    </Column>
  )
}

function DataRow<T>({ row, columns, gapBetweenColumns }: DataRowProps<T>) {
  return (
    <TableRow gap={gapBetweenColumns}>
      {columns.map(({ flex, renderCell }, index) => (
        <Box key={`dataRowCell_${index}`} flex={flex ?? 1}>
          {renderCell(row)}
        </Box>
      ))}
    </TableRow>
  )
}

function TableRow({ children, gap }: TableRowProps) {
  return (
    <Row alignCenter padHorizontal="XXXXXS" gap={gap}>
      {children}
    </Row>
  )
}

function HeaderSeparator<T>({
  gapTopBottom,
  headerSeparatorBold,
}: HeaderSeparatorProps<T>) {
  return (
    <>
      <RowSeparator color={headerSeparatorBold ? "default" : undefined} />
      <Gap vertical={gapTopBottom} />
    </>
  )
}

function DataRowSeparator<T>({ gapBetweenRows }: DataRowSeparatorProps<T>) {
  return (
    <>
      <Gap vertical={gapBetweenRows} />
      <RowSeparator />
      <Gap vertical={gapBetweenRows} />
    </>
  )
}

function EndSeparator<T>({ gapTopBottom }: EndSeparatorProps<T>) {
  return (
    <>
      <Gap vertical={gapTopBottom} />
      <RowSeparator />
    </>
  )
}
