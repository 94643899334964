import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type TextAlign = "left" | "right" | "center"

export type ReceiptDesignEntry = {
  text: string
  bold: boolean
  underline: boolean

  textAlign?: TextAlign
}

export type SharedOptionalReceiptDesign = {
  headerImage?: number[]
  showHeaderEntries?: boolean
  showFooterEntries?: boolean
  header?: ReceiptDesignEntry[]
  footer?: ReceiptDesignEntry[]
  headerImageAspectRatio?: number
}

const ReceiptDesignEntryEntity = createEntityFor<ReceiptDesignEntry>()
  .withSchema(
    z.object({
      text: z.string(),
      bold: z.boolean(),
      underline: z.boolean(),
      textAlign: z.enum(["left", "right", "center"]).optional(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const ReceiptDesignEntity =
  createEntityFor<SharedOptionalReceiptDesign>()
    .withSchema(
      z.object({
        headerImage: z.array(z.number()).optional(),
        showHeaderEntries: z.oboolean(),
        showFooterEntries: z.oboolean(),
        header: z.array(ReceiptDesignEntryEntity.schema).optional(),
        footer: z.array(ReceiptDesignEntryEntity.schema).optional(),
      }),
    )
    .serialize(data => data)
    .deserialize(json => json)
