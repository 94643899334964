import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ItemArticle } from "./Article"
import { OutputInvoiceItem } from "./InvoiceItem"
import { TaxInfoEntity } from "./TaxInfo"

export type ReceiptItem = Omit<
  OutputInvoiceItem,
  "createdAt" | "modifiedAt" | "isDiscountBlocked" | "article" | "discounts"
> & {
  discountNames: string[]
  article: Omit<
    ItemArticle,
    "articleId" | "articleGroupId" | "mainGroupId" | "number"
  >
}

export type DetailedArticleReceiptItem = Omit<ReceiptItem, "article"> & {
  article: ItemArticle
}

export const ReceiptItemEntity = createEntityFor<ReceiptItem>()
  .withSchema(
    z.object({
      invoiceItemId: z.string(),
      amount: z.string(),
      taxInfo: TaxInfoEntity.schema,
      discountNames: z.array(z.string()),
      article: z.object({
        name: z.string(),
        price: z.string(),
        taxRate: z.number(),
      }),
      isNegativeAmount: z.boolean(),
      additionalText: z.ostring(),
    }),
  )
  .serialize(data => ({
    ...data,
    amount: data.amount.toString(),
    article: {
      name: data.article.name,
      price: data.article.price.toString(),
      taxRate: data.article.taxRate,
    },
    taxInfo: TaxInfoEntity.serialize(data.taxInfo),
  }))
  .deserialize(json => ({
    ...json,
    amount: Big(json.amount),
    article: {
      name: json.article.name,
      taxRate: json.article.taxRate,
      price: Big(json.article.price),
    },
    taxInfo: TaxInfoEntity.deserialize(json.taxInfo),
  }))
