import * as z from "zod"

import { MCIcon } from "@axtesys/kassen-app-ui"
import { createEntityFor } from "@axtesys/react-tools"

export type PaymentMethodId = string

export type PaymentMethodProperties = {
  rksvMandatory: boolean
  usableForPayment: boolean
  usableForPayout: boolean
  isCardPayment: boolean
  openDrawer: boolean
  overpayAllowed: boolean
}

type ProgrammaticPaymentMethodProperties = {
  originOfTip: boolean
  attributionForTip: boolean
  affectsCashTransfers: boolean
}

export type PaymentMethod = {
  paymentMethodId: PaymentMethodId
  icon: MCIcon
  methodName: string
  properties: PaymentMethodProperties
  programmaticProperties: ProgrammaticPaymentMethodProperties
}

export const PaymentMethodEntity = createEntityFor<PaymentMethod>()
  .withSchema(
    z.object({
      paymentMethodId: z.string(),
      methodName: z.string(),
      icon: z.string(),
      properties: z.object({
        rksvMandatory: z.boolean(),
        usableForPayment: z.boolean(),
        usableForPayout: z.boolean(),
        isCardPayment: z.boolean(),
        openDrawer: z.boolean(),
        overpayAllowed: z.boolean(),
      }),
      programmaticProperties: z.object({
        originOfTip: z.boolean(),
        attributionForTip: z.boolean(),
        affectsCashTransfers: z.boolean(),
      }),
    }),
  )
  .serialize(data => data)
  .deserialize(json => ({ ...json, icon: json.icon as MCIcon }))
