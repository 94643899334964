import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ManagerId } from "./Manager"

export type CompanyId = string

export type Company = {
  name: string
  taxId: string
  street: string
  streetNumber: string
  zipCode: string
  city: string
}

export type CompanyData = {
  companyId: CompanyId
  name: string
  taxId: string
  manager: {
    managerId: ManagerId
    email: string
    phoneNumber?: string
  }
  address: {
    street: string
    streetNumber: string
    zipCode: string
    city: string
  }
}

export const CompanyDataEntity = createEntityFor<CompanyData>()
  .withSchema(
    z.object({
      companyId: z.string(),
      name: z.string(),
      taxId: z.string(),
      manager: z.object({
        managerId: z.string(),
        email: z.string(),
        phoneNumber: z.ostring(),
      }),
      address: z.object({
        street: z.string(),
        streetNumber: z.string(),
        zipCode: z.string(),
        city: z.string(),
      }),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
