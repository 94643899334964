import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import {
  OperatorFeaturesInput,
  OperatorPermissionInput,
  Salutation,
} from "../api/graphql/types"

export type OperatorId = string

export type OperatorBaseData = {
  salutation: Salutation
  firstName: string
  lastName: string
  password: string
  repeatedPassword: string

  operatorCode?: number
}

export type Operator = OperatorBaseData & {
  id: OperatorId
  permissions: OperatorPermissionInput
  features: OperatorFeaturesInput
}

export type OperatorData = Omit<Operator, "id">

export type OperatorReceiptData = {
  operatorId: OperatorId
  salutation: Salutation
  fullName: string
}

export const OperatorReceiptDataEntity = createEntityFor<OperatorReceiptData>()
  .withSchema(
    z.object({
      operatorId: z.string(),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      fullName: z.string(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
