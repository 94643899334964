import { Big } from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ValueTransfer } from "./ValueTransfer"

export type TipAttribution = Omit<ValueTransfer, "amount">

export type TipTransfer = {
  amount: Big
  originateFrom: TipAttribution
  considerFor?: TipAttribution
}

export type DisplayTipTransfer = {
  amount: Big
  originateFrom: Pick<TipAttribution, "methodName">
}

export const DisplayTipTransferEntity = createEntityFor<DisplayTipTransfer>()
  .withSchema(
    z.object({
      amount: z.string(),
      originateFrom: z.object({ methodName: z.string() }),
    }),
  )
  .serialize(data => ({ ...data, amount: data.amount.toString() }))
  .deserialize(json => ({ ...json, amount: Big(json.amount) }))
