import { maxBy } from "lodash"
import z from "zod"

import { createId, jsonDbAtom } from "@axtesys/react-tools"

import { operatorSelector } from "~shared/feature/Authentication/state"
import { selector } from "~shared/lib/recoil/lib"
import {
  DisplayReceipt,
  DisplayReceiptEntity,
  ReceiptId,
} from "~shared/types/Receipt"

import {
  cashRegisterState,
  cashRegisterWithoutNumberSelector,
} from "../CashRegister/state"
import { companyState, taxOfficeCashBoxIdSelector } from "../OfflineData/state"
import {
  outputPaymentsSelector,
  payoutsSelector,
  receiptTextState,
  tipsSelector,
} from "../Payment/state"
import {
  cartDiscountHistoryState,
  cartItemsHistoryState,
  cartTotalRoundingDifferenceSelector,
  currentInvoiceContactBillingAddressDataSelector,
  currentInvoiceDepartmentDataSelector,
  currentInvoiceShippingAddressDataSelector,
  discountableCartItemsPresentSelector,
  evaluatedCartItemsSelector,
} from "../ShoppingCart/state"
import { autoPrintPrinterIdState } from "../Walkthrough/state"
import { createReceipt } from "./lib"

// ID created on client changes with every cart.
//
// Attention: Do not change the key of this atom,
// as it would reset on the next initialisation!
// This would lead to several errors and problems!
export const receiptIdState = jsonDbAtom({
  key: "invoiceId",
  default: createId(),
  schema: z.string(),
})

// Receipt count received from server during pairing.
// Is incremented every time we successfully submit a Receipt.
//
// Attention: Do not change the key of this atom,
// as it would reset on the next initialisation!
// This would lead to several errors and problems!
export const receiptCountState = jsonDbAtom({
  key: "invoiceCount",
  default: 1,
  schema: z.number(),
})

// The effective `receiptNumber` we print on the Receipt.
// Something like "K02-000124".
export const receiptNumberSelector = selector({
  key: "receiptNumber",
  get: ({ get }) => {
    const receiptNumber = get(receiptCountState)
    const { cashRegisterNumber } = get(cashRegisterState)

    const registerPart = cashRegisterNumber.toString().padStart(2, "0")
    const receiptPart = receiptNumber.toString().padStart(6, "0")

    return `K${registerPart}-${receiptPart}`
  },
})

export const receiptSelector = selector({
  key: "receiptSelector",
  get: ({ get }) => {
    let cartDiscountsHistory = get(cartDiscountHistoryState)

    if (
      cartDiscountsHistory.length > 0 &&
      !get(discountableCartItemsPresentSelector) &&
      maxBy(
        cartDiscountsHistory,
        historyEntry => historyEntry.createdAt,
      )!.discounts.some(discount => discount.absolute || discount.percent)
    ) {
      cartDiscountsHistory = [
        ...cartDiscountsHistory,
        { discounts: [], createdAt: new Date() },
      ]
    }

    return createReceipt({
      receiptId: get(receiptIdState),
      taxOfficeCashBoxId: get(taxOfficeCashBoxIdSelector),
      receiptNumber: get(receiptNumberSelector),

      company: get(companyState),
      operator: get(operatorSelector),
      cashRegister: get(cashRegisterWithoutNumberSelector),

      department: get(currentInvoiceDepartmentDataSelector),
      shippingAddress: get(currentInvoiceShippingAddressDataSelector),
      billingAddress: get(currentInvoiceContactBillingAddressDataSelector),

      items: get(evaluatedCartItemsSelector),
      itemsHistory: get(cartItemsHistoryState),
      cartDiscountsHistory,

      payments: get(outputPaymentsSelector),
      payouts: get(payoutsSelector),
      tips: get(tipsSelector),

      printerId: get(autoPrintPrinterIdState) ?? "email",
      receiptText: get(receiptTextState),

      roundingDifference: get(cartTotalRoundingDifferenceSelector),
    })
  },
})

export const lastSuccessfulReceiptIdState = jsonDbAtom({
  key: "lastSuccessfulReceiptId",
  default: undefined as ReceiptId | undefined,
  schema: z.ostring(),
})

export const lastSuccessfulCancelReceiptIdState = jsonDbAtom({
  key: "lastSuccessfulCancelReceiptId",
  default: undefined as ReceiptId | undefined,
  schema: z.ostring(),
})

export const latestCompletedDisplayReceiptState = jsonDbAtom({
  key: "latestCompletedDisplayReceipt",
  default: undefined as DisplayReceipt | undefined,
  schema: DisplayReceiptEntity.schema,
  serialize: DisplayReceiptEntity.serialize,
  deserialize: DisplayReceiptEntity.deserialize,
})
