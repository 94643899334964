import { mapValues } from "lodash"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { KeysMainGroupInput } from "../api/graphql/types"
import { CashRegisterId } from "./CashRegister"
import {
  KeysMainGroupContent,
  KeysMainGroupContentEntity,
  KeysMainGroupId,
  SpecificKeysMainGroupContent,
} from "./KeysMainGroup"

export type KeyboardLayoutId = string

export type KeyboardLayoutContent = {
  name: string
  columnCountPortrait: number
  columnCountLandscape: number
  assignedCashRegisters: CashRegisterId[]
  content: KeysMainGroupContent[]

  keyboardLayoutId?: KeyboardLayoutId
}

export type SpecificKeyboardLayoutContent = Omit<
  KeyboardLayoutContent,
  "keyboardLayoutId" | "assignedCashRegisters" | "content"
> & { keysMainGroups: Record<KeysMainGroupId, SpecificKeysMainGroupContent> }

export type KeyboardLayoutData = Omit<KeyboardLayoutContent, "content"> & {
  content: KeysMainGroupInput[]
}

export type KeyboardLayoutNameData = Pick<KeyboardLayoutData, "name">

export const KeyboardLayoutContentEntity = createEntityFor<
  SpecificKeyboardLayoutContent | undefined
>()
  .withSchema(
    z.optional(
      z.object({
        name: z.string(),
        columnCountPortrait: z.number(),
        columnCountLandscape: z.number(),
        keysMainGroups: z.record(KeysMainGroupContentEntity.schema),
      }),
    ),
  )
  .serialize(data =>
    data
      ? {
          ...data,
          keysMainGroups: mapValues(
            data.keysMainGroups,
            KeysMainGroupContentEntity.serialize,
          ),
        }
      : undefined,
  )
  .deserialize(json =>
    json
      ? {
          ...json,
          keysMainGroups: mapValues(
            json.keysMainGroups,
            KeysMainGroupContentEntity.deserialize,
          ),
        }
      : undefined,
  )
