import React from "react"
import { ScrollView, ScrollViewProps, StyleProp, ViewStyle } from "react-native"

import { useScreenSizeType } from "../hooks/useScreenSizeType"
import { useTheme } from "../theme"
import { ResponsiveSpacing } from "../types"
import { Box, BoxProps, retrieveSpacingPixels, useBoxStyle } from "./Box"

type ExtendedBoxProps = BoxProps &
  Pick<
    ScrollViewProps,
    "showsVerticalScrollIndicator" | "showsHorizontalScrollIndicator"
  >
type FlexDirectionProps = { flexDirection: "row" | "column" }
type GapStyleProps = Pick<ScrollViewProps, "contentContainerStyle">

type FlexBoxProps = ExtendedBoxProps & {
  // Enables scrolling within the container along its main axis.
  scrollable?: boolean

  // Inserts equal spaces / gaps along the container's main axis
  // that use the available space as a reference
  // (between, in front and at the end of the passed elements;
  //  when 'spaceBetween' is set to 'true',
  //  setting this property will have no effect).
  spaceEvenly?: boolean

  // Inserts equal spaces / gaps along the container's main axis
  // that use the available space as a reference (between passed elements).
  spaceBetween?: boolean

  // Adds a gap between passed children along the container's main axis.
  gap?: ResponsiveSpacing
}

type SharedFlexBoxProps = Omit<FlexBoxProps, "gap"> &
  FlexDirectionProps &
  GapStyleProps
type GappedFlexBoxProps = SharedFlexBoxProps & { gap: ResponsiveSpacing }
type ScrollableBoxProps = ExtendedBoxProps & FlexDirectionProps & GapStyleProps

export type RowProps = Omit<FlexBoxProps, "showsVerticalScrollIndicator">
export type ColumnProps = Omit<FlexBoxProps, "showsHorizontalScrollIndicator">

export function Row(props: RowProps) {
  return <FlexBox {...props} flexDirection="row" />
}

export function Column(props: ColumnProps) {
  return <FlexBox {...props} flexDirection="column" />
}

function FlexBox(props: FlexBoxProps & FlexDirectionProps) {
  return props.gap ? (
    <GappedFlexBox {...props} gap={props.gap} />
  ) : (
    <SharedFlexBox {...props} />
  )
}

function SharedFlexBox({
  style,
  children,
  scrollable,
  spaceEvenly,
  spaceBetween,
  flexDirection,
  contentContainerStyle,
  ...props
}: SharedFlexBoxProps) {
  const boxStyle: StyleProp<ViewStyle> = [
    {
      flexDirection,
      justifyContent: spaceBetween
        ? "space-between"
        : spaceEvenly
        ? "space-evenly"
        : undefined,
    },
    !scrollable && contentContainerStyle,
    style,
  ]

  if (scrollable)
    return (
      <ScrollableBox
        {...props}
        style={boxStyle}
        flexDirection={flexDirection}
        contentContainerStyle={contentContainerStyle}
      >
        {children}
      </ScrollableBox>
    )

  return (
    <Box {...props} style={boxStyle}>
      {children}
    </Box>
  )
}

function GappedFlexBox({ gap, ...props }: GappedFlexBoxProps) {
  const theme = useTheme()
  const screenSizeType = useScreenSizeType()

  const horizontalSpacing =
    props.flexDirection == "row"
      ? retrieveSpacingPixels(theme, screenSizeType, gap)
      : undefined
  const verticalSpacing =
    props.flexDirection == "column"
      ? retrieveSpacingPixels(theme, screenSizeType, gap)
      : undefined
  const gapStyle = { rowGap: verticalSpacing, columnGap: horizontalSpacing }

  return <SharedFlexBox {...props} contentContainerStyle={gapStyle} />
}

function ScrollableBox({
  style,
  children,
  contentContainerStyle,
  showsVerticalScrollIndicator,
  showsHorizontalScrollIndicator,
  ...props
}: ScrollableBoxProps) {
  const isRow = props.flexDirection == "row"

  return (
    <ScrollView
      horizontal={isRow}
      keyboardShouldPersistTaps="handled"
      style={[useBoxStyle(props), style]}
      contentContainerStyle={[
        contentContainerStyle,
        !isRow ? { paddingBottom: 24 } : undefined,
      ]}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
    >
      {children}
    </ScrollView>
  )
}
