import { isArray } from "lodash"
import { useCallback, useMemo } from "react"
import { useRecoilCallback } from "recoil"

import {
  createCustomContext,
  createId,
  formatIsoDateTime,
} from "@axtesys/react-tools"
import {
  QueryFunctionContext,
  useQueryClient,
  useSuspenseQuery as useRQSuspenseQuery,
} from "@tanstack/react-query"

import { useSnackbar } from "../../components/providers/SnackbarProvider"
import { useIsLoggedIn, useLogoutAndFail } from "../../feature/Authentication"
import {
  useErrorNoticeDialog,
  useSystemTimeDialog,
} from "../../feature/Dialog/hooks"
import {
  FRAUD_PROTECTION,
  MAX_ALLOWED_FRAUD_DELTA,
  MUTATION_IN_FUTURE,
  MUTATION_IN_PAST,
} from "../../feature/FraudProtection/constants"
import { lastServerInteractionState } from "../../feature/FraudProtection/state"
import { logError, logInfo, logTrace, logWarn } from "../../feature/Logging/lib"
import { useTranslations } from "../../feature/Translation/hooks"
import { isNetworkError, transformError } from "../../lib/Errors"
import { useHttp } from "../http/useHttp"
import {
  getOperationQueryString,
  OperationVariables,
  QueryData,
  QueryNames,
} from "./GeneratedOperations.helper"
import {
  DUPLICATE_COMMAND,
  NO_DATA_ERROR,
  QUERY_REQUEST_ERROR,
  TRANSLATION_MISSING,
  UNAUTHENTICATED,
  UNEXPECTED_RESPONSE_BODY,
} from "./constants"
import {
  GraphQLHttpResponse,
  MutationInvalidation,
  SendMutation,
  SendMutationInternal,
  SendOperation,
  SendQuery,
} from "./types"

export * from "./types"
export * from "./constants"

// Should prevent the unnecessary logging and clogging of error messages
// resulting from operation requests that are sent and fail over and over again
// (e.g. via network issues / unstable connections).
const mutationSeenHandledErrors: Record<string, boolean> = {}
const mutationSeenUnhandledErrors: Record<string, boolean> = {}

export const [GraphQLContextProvider, useGraphQL] = createCustomContext(() => {
  const http = useHttp()
  const {
    extractErrorCode,
    extractErrorAdditionalInfo,
    transformErrorCodeToMessage,
  } = useMutationErrorHandling()
  const isLoggedIn = useIsLoggedIn()
  const { showSnackbar } = useSnackbar()
  const logoutAndFail = useLogoutFailLogic()
  const { tGeneral, tValidations } = useTranslations()
  const invalidateReactQueries = useInvalidateQueries()
  const { showSystemTimeDialog } = useSystemTimeDialog()
  const { showErrorDialog, showErrorSnackbar } = useUserErrorPresentation()

  const sendOperation: SendOperation = useCallback(
    async (operationName, variables, commandId, createdAt, timeout) => {
      // Send the operation request via the custom implementation of 'fetch'.
      const response = await http({
        method: "POST",
        path: "/api/graphql",

        // GraphQL requests should always be designed
        // to be completed before a maximum execution /
        // wait duration of 30 seconds is reached.
        // If that is not the case, the request
        // should be improved / sized down to an acceptable size.
        //
        // If the request in general is designed accordingly,
        // but the network connection is awful (but still present)
        // also cancel the request after 30 seconds
        // to prevent an accumulation of pending requests in the background.
        //
        // For certain requests the timeout needs
        // to be configured with a more strict approach (e.g. receipt creation),
        // therefore allow configuring the timeout,
        // but also set a standard timeout of 30 seconds.
        //
        // However, configure this value wisely as a too low timeout value,
        // could lead to repeated fails (in terms of offline mutations) or
        // the request never getting through at all.
        timeout: timeout ?? 30000,

        headers: {
          // "X-Created-At" and "X-Command-Id" header fields
          // should only be added in case of a mutation.
          // Queries do not require such fields.
          "X-Created-At": createdAt,
          "X-Command-Id": commandId,

          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables,
          query: getOperationQueryString(operationName),
        }),
      })

      if (isLoggedIn && response.status == 401) return logoutAndFail()

      const responseBody = await response.text()
      if (!responseBody.startsWith("{")) {
        throw Error(`${UNEXPECTED_RESPONSE_BODY}: ${responseBody}`)
      }

      let responseObject: GraphQLHttpResponse<any> = JSON.parse(responseBody)
      if (!("data" in responseObject)) {
        throw Error(`${NO_DATA_ERROR}: ${responseBody}`)
      }

      logTrace(
        `Received following GraphQL response for operation '${operationName}'`,
        responseObject,
      )

      responseObject = {
        errors: responseObject.errors,
        data: JSON.parse(JSON.stringify(responseObject.data), (_, value) =>
          value != null ? value : undefined,
        ),
      } as GraphQLHttpResponse<any>

      if (
        isLoggedIn &&
        responseObject.errors &&
        responseObject.errors.some(
          error => error.extensions?.errorCode == UNAUTHENTICATED,
        )
      ) {
        return logoutAndFail()
      }

      return responseObject
    },
    [http, isLoggedIn, logoutAndFail],
  )

  const sendQuery: SendQuery = useCallback(
    async (operationName, variables, timeout) => {
      const { data, errors } = await sendOperation(
        operationName,
        variables,
        undefined,
        undefined,
        timeout,
      )

      if (errors) {
        logError(`Error when fetching query '${operationName}'`, errors)

        throw Error(
          `${QUERY_REQUEST_ERROR} (${
            errors.length > 0
              ? errors.map(error => error.extensions?.errorCode ?? "unknown")
              : "unknown"
          })`,
        )
      }

      return data
    },
    [sendOperation],
  )

  const sendMutationInternal = useRecoilCallback(
    ({ snapshot }) =>
      async (
        operationName,
        variables,
        commandId,
        createdAt,
        timeout,
        excludeFromLogging,
        offlineMutation,
      ) => {
        try {
          // Verify that the mutation is not in the past,
          // otherwise it should not be accepted,
          // but rather logged.

          const lastServerInteraction = snapshot
            .getLoadable(lastServerInteractionState)
            .getValue()
          const currentClientInteraction = Date.now()

          if (
            offlineMutation != true &&
            lastServerInteraction - currentClientInteraction >
              MAX_ALLOWED_FRAUD_DELTA
          ) {
            // In that case, display a dialog that notifies the potential fraudster,
            // that such an action cannot be carried out
            // and adjustments to the system time need to be performed in order to proceed.
            // In addition, log the possible fraudulent attempt.

            removeExcludedFieldsFromVariables()
            logWarn(
              `${FRAUD_PROTECTION}: A GraphQL mutation was tried to be set off in the past`,
              {
                operationName,
                createdAt,
                commandId,
                variables,
                lastServerInteraction: formatIsoDateTime(lastServerInteraction),
                currentClientInteraction: formatIsoDateTime(
                  currentClientInteraction,
                ),
              },
            )
            showSystemTimeDialog("past")

            return {
              status: "failed",
              additionalInfo: undefined,
              errorCode: MUTATION_IN_PAST,
              errorMessage: tValidations("validationMutationInPastOrFuture", {
                type: tGeneral("past"),
              }),
            }
          }

          const { data, errors } = await sendOperation(
            operationName,
            variables,
            commandId,
            createdAt,
            timeout,
          )

          if (!errors) {
            // Happy path:
            // The operation request has been handled successfully,
            // therefore log the success and return the received data.

            logInfo(`Successfully executed mutation '${operationName}'`)

            return { data, status: "success" }
          } else {
            // Sad path:
            // The operation request was not successful,
            // but the error is still formed as a valid error response
            // by the server / GraphQL.
            // Therefore, try to handle it further in here.

            const errorCode = extractErrorCode(errors)
            const additionalInfo = extractErrorAdditionalInfo(errors)

            let errorMessage: string
            if (errorCode == undefined) {
              // There is no errorCode within the response,
              // therefore log and output a generic error.

              logError(
                `Unexpected GraphQL error occurred during execution of mutation '${operationName}'`,
                errors,
              )

              errorMessage = tGeneral("errorGeneric")
            } else {
              // In this case an errorCode is present within the response,
              // therefore handle it accordingly and
              // execute possible side effects.

              switch (errorCode) {
                case UNAUTHENTICATED:
                  logWarn(
                    `A GraphQL mutation was tried to be set off, but the user is no longer authenticated`,
                    { operationName, createdAt, commandId },
                  )
                  break
                case MUTATION_IN_FUTURE:
                  logWarn(
                    `${FRAUD_PROTECTION}: A GraphQL mutation was tried to be set off in the future`,
                    { operationName, createdAt, commandId },
                  )

                  // We do not want to await the result
                  // of this confirmation dialog,
                  // as otherwise the upcoming logs
                  // could be omitted via closing the application.
                  showSystemTimeDialog("future")
                  break
                default:
                  break
              }

              errorMessage = transformErrorCodeToMessage(
                operationName,
                errorCode,
              )
            }

            if (!mutationSeenHandledErrors[commandId]) {
              removeExcludedFieldsFromVariables()
              mutationSeenHandledErrors[commandId] = true
              logError(
                `Mutation '${operationName}' created on '${
                  createdAt ?? formatIsoDateTime(Date.now())
                }' failed to execute (${errorCode})`,
                {
                  commandId,
                  variables,
                  errors,
                  errorCode,
                  errorMessage,
                  additionalInfo,
                },
              )
            } else {
              logError(
                `Failed to execute mutation '${commandId}' (${errorCode})`,
              )
            }

            return { errorCode, errorMessage, additionalInfo, status: "failed" }
          }
        } catch (error: any) {
          // Extra sad path:
          // An unforeseen error occurred during the operation request,
          // therefore cancel at this point, log the request and return.

          const errorMessage = isNetworkError(error)
            ? tGeneral("errorConnectionLost")
            : `${tGeneral("errorGeneric")} (${error?.message})`

          if (!mutationSeenUnhandledErrors[commandId]) {
            removeExcludedFieldsFromVariables()
            mutationSeenUnhandledErrors[commandId] = true
            logError(
              `Mutation '${operationName}' created on '${
                createdAt ?? formatIsoDateTime(Date.now())
              }' failed to execute`,
              { commandId, variables, error: transformError(error).error },
            )
          } else logError(`Failed to execute mutation '${commandId}'`)

          return { errorMessage, status: "failed", errorCode: undefined }
        }

        function removeExcludedFieldsFromVariables() {
          if (excludeFromLogging) {
            const excludedFields = !isArray(excludeFromLogging)
              ? [excludeFromLogging]
              : excludeFromLogging

            for (const field of excludedFields) delete variables[field]
          }
        }
      },
    [
      extractErrorAdditionalInfo,
      extractErrorCode,
      sendOperation,
      showSystemTimeDialog,
      tGeneral,
      tValidations,
      transformErrorCodeToMessage,
    ],
  ) as SendMutationInternal

  const sendMutation: SendMutation = useCallback(
    async (
      operationName,
      {
        timeout,
        commandId,
        createdAt,
        variables,
        snackMessage,
        offlineMutation,
        invalidateQueries,
        excludeFromLogging,
        genericErrorHandling = true,
        throwFraudRelatedErrors = false,
        onError,
        onSuccess,
      },
    ) => {
      const result = await sendMutationInternal(
        operationName,
        variables,
        commandId ?? createId(),
        createdAt ?? formatIsoDateTime(Date.now()),
        timeout,
        excludeFromLogging,
        offlineMutation,
      )

      if (result.status == "success") {
        await invalidateReactQueries(invalidateQueries)

        await onSuccess?.(result)

        if (snackMessage) {
          const message = snackMessage(result)

          if (message != undefined) {
            showSnackbar({
              message,
              mode: "success",
              key: `mutation${operationName}Success`,
            })
          }
        }

        return result
      }

      const isNotFraudErrorCode =
        result.errorCode != MUTATION_IN_PAST &&
        result.errorCode != MUTATION_IN_FUTURE

      if (
        onError != undefined &&
        (throwFraudRelatedErrors || isNotFraudErrorCode)
      ) {
        await onError(result, showErrorSnackbar, showErrorDialog)
      }

      if (genericErrorHandling) {
        if (result.errorCode == UNAUTHENTICATED) {
          showErrorSnackbar(
            tGeneral("errorUserLoggedOut"),
            "loggedOutByServerError",
          )
        } else if (isNotFraudErrorCode) {
          showErrorSnackbar(
            result.errorMessage,
            `mutation${operationName}${result.errorCode}`,
          )
        }
      }

      return result
    },
    [
      invalidateReactQueries,
      sendMutationInternal,
      showErrorDialog,
      showErrorSnackbar,
      showSnackbar,
      tGeneral,
    ],
  )

  return { sendQuery, sendMutation }
})

function useMutationErrorHandling() {
  const { tErrors, tGeneral, tValidations } = useTranslations()

  const extractErrorCode = useCallback(
    (errors: GraphQLHttpResponse<any>["errors"]): string | undefined => {
      for (const error of errors ?? []) {
        if (error.extensions?.errorCode) {
          return error.extensions.errorCode
        }
      }
      return undefined
    },
    [],
  )

  const extractErrorAdditionalInfo = useCallback(
    (errors: GraphQLHttpResponse<any>["errors"]): string | undefined => {
      for (const error of errors ?? []) {
        if (error.extensions?.additionalInfo) {
          return error.extensions.additionalInfo
        }
      }
      return undefined
    },
    [],
  )

  const transformErrorCodeToMessage = useCallback(
    (operationName: string, errorCode: string) => {
      const mappedError = tErrors(
        `${operationName}.${errorCode}`.trim(),
        TRANSLATION_MISSING,
      ) as string | undefined

      if (mappedError && mappedError != TRANSLATION_MISSING) return mappedError

      let message
      switch (errorCode) {
        case UNAUTHENTICATED:
          message = tGeneral("errorUserLoggedOut")
          break
        case DUPLICATE_COMMAND:
          message = tGeneral("errorDuplicateCommand")
          break
        case MUTATION_IN_FUTURE:
          message = tValidations("validationMutationInPastOrFuture", {
            type: tGeneral("future"),
          })
          break
        default:
          message = `${tGeneral("errorGeneric")} (${errorCode})`
          break
      }

      return message
    },
    [tErrors, tGeneral, tValidations],
  )

  return {
    extractErrorCode,
    extractErrorAdditionalInfo,
    transformErrorCodeToMessage,
  }
}

function useUserErrorPresentation() {
  const { showSnackbar } = useSnackbar()
  const { tGeneral, tValidations } = useTranslations()
  const { showErrorNoticeDialog } = useErrorNoticeDialog()

  const pastFutureErrorMessages = useMemo(
    () => [
      tValidations("validationMutationInPastOrFuture", {
        type: tGeneral("past"),
      }),
      tValidations("validationMutationInPastOrFuture", {
        type: tGeneral("future"),
      }),
    ],
    [tGeneral, tValidations],
  )

  const showErrorSnackbar = useCallback(
    (message?: string, key?: string) => {
      if (message == undefined || pastFutureErrorMessages.includes(message))
        return

      showSnackbar({
        message,
        mode: "error",
        duration: 5000,
        key: key ?? createId(),
      })
    },
    [pastFutureErrorMessages, showSnackbar],
  )

  const showErrorDialog = useCallback(
    (message?: string) => {
      if (message == undefined || pastFutureErrorMessages.includes(message))
        return

      showErrorNoticeDialog(message)
    },
    [pastFutureErrorMessages, showErrorNoticeDialog],
  )

  return { showErrorDialog, showErrorSnackbar }
}

function useLogoutFailLogic() {
  const logoutAndFail = useLogoutAndFail()

  return useCallback(() => {
    logoutAndFail()

    return {
      data: undefined,
      errors: [
        {
          message: UNAUTHENTICATED,
          extensions: { errorCode: UNAUTHENTICATED },
        },
      ],
    } as GraphQLHttpResponse<any>
  }, [logoutAndFail])
}

function reactQueryFunction({ queryKey }: QueryFunctionContext) {
  const [operationName, variables, sendQuery] = queryKey
  const send = sendQuery as Function
  return send(operationName, variables)
}

export function useSuspenseQuery<Q extends QueryNames>(
  operationName: Q,
  variables: OperationVariables<Q>,
): QueryData<Q> {
  const { sendQuery } = useGraphQL()

  const { data } = useRQSuspenseQuery({
    queryKey: [operationName, variables, sendQuery],
    queryFn: reactQueryFunction,
  })

  return data
}

export function useInvalidateQueries() {
  const queryClient = useQueryClient()

  return useCallback(
    async (invalidateQueries?: MutationInvalidation) => {
      if (!invalidateQueries) return

      if (typeof invalidateQueries == "string") {
        await queryClient.invalidateQueries({ queryKey: [invalidateQueries] })
      } else if (Array.isArray(invalidateQueries)) {
        invalidateQueries.forEach(queryName =>
          queryClient.invalidateQueries({ queryKey: [queryName] }),
        )
      }
    },
    [queryClient],
  )
}
