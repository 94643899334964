import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type DepartmentId = string

export type Department = {
  departmentId: DepartmentId
  name: string
  isDefault: boolean
}

export type DepartmentData = Omit<Department, "isDefault">

export const DepartmentEntity = createEntityFor<Department>()
  .withSchema(
    z.object({
      departmentId: z.string(),
      name: z.string(),
      isDefault: z.boolean(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const DepartmentDataEntity = createEntityFor<DepartmentData>()
  .withSchema(z.object({ departmentId: z.string(), name: z.string() }))
  .serialize(data => data)
  .deserialize(json => json)
