import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import {
  ArticleKeyContent,
  ArticleKeyContentEntity,
  ArticleKeyId,
} from "./ArticleKey"

export type KeysSubGroupId = string

export type KeysGroupBaseData = {
  name: string
  color: string
}

export type KeysSubGroupContent = KeysGroupBaseData & {
  keysSubGroupId: KeysSubGroupId
  orderIndex: number
  articleKeys: ArticleKeyContent[]
}

export type KeysSubGroupData = KeysGroupBaseData & {
  articleKeys?: ArticleKeyId[]
  keysMainGroupId?: string
}

export type KeysSubGroupDataWithId = KeysSubGroupData &
  Pick<KeysSubGroupContent, "keysSubGroupId">

export const KeysSubGroupContentEntity = createEntityFor<KeysSubGroupContent>()
  .withSchema(
    z.object({
      keysSubGroupId: z.string(),
      name: z.string(),
      color: z.string(),
      orderIndex: z.number(),
      articleKeys: z.array(ArticleKeyContentEntity.schema),
    }),
  )
  .serialize(data => ({
    ...data,
    articleKeys: data.articleKeys.map(ArticleKeyContentEntity.serialize),
  }))
  .deserialize(json => ({
    ...json,
    articleKeys: json.articleKeys.map(ArticleKeyContentEntity.deserialize),
  }))
