import Big from "big.js"
import { concat, groupBy, last, orderBy, uniq } from "lodash"

import { formatIsoDateTime } from "@axtesys/react-tools"

import {
  ArticleInput,
  DetailedReceiptItemFragment,
  DiscountInput,
  HistoricInvoiceItemInput,
  InvoiceItemInput,
  ReceiptInput,
  TipAttributionFragment,
  TipAttributionInput,
  TipTransferFragment,
  TipTransferInput,
  ValueTransferFlagsFragment,
  ValueTransferFragment,
  ValueTransferInput,
} from "~shared/api/graphql/types"
import { logWarn } from "~shared/feature/Logging/lib"
import { transformFromGraphQLToSharedReceiptItem } from "~shared/feature/Receipt/lib"
import { t_General } from "~shared/feature/Translation/lib"
import { maxOfBigs, minOfBigs, sumBigArray } from "~shared/lib/Big"
import { bigToEuros, eurosToBig } from "~shared/lib/Money"
import {
  ArticleGroup,
  ArticleGroupId,
  DetailedArticleReceiptItem,
  Discount,
  InputReceipt,
  InvoiceItem,
  ItemArticle,
  MainGroup,
  MainGroupId,
  OutputInvoiceItem,
  OutputReceipt,
  PaymentMethod,
  PaymentMethodId,
  PreOutputReceipt,
  TaxInfo,
  TipAttribution,
  TipTransfer,
  TotalTaxInfo,
  ValueTransfer,
} from "~shared/types"

import { determineOutputType } from "../Printers/lib"
import {
  evaluateInvoiceItem,
  transformDiscountsToDiscountsWithType,
} from "../ShoppingCart/lib"

// Composition functions

export function createReceipt(receipt: InputReceipt): PreOutputReceipt {
  // Items that should be marked as voided or zero.
  const zeroAndVoidedItems = retrieveZeroAndVoidedItems(
    receipt.items,
    receipt.itemsHistory,
  )

  // Calculate the summarized taxInfo of the receipt (for TaxInfoTable) and total sums.
  // groupedTaxInfo: TaxInfo sums grouped by their corresponding taxRate
  // totalTaxInfo: Total sums of all taxRate groups
  const { taxInfos, totalTaxInfo } = calculateTaxInfos(
    receipt.items,
    receipt.roundingDifference,
  )

  // The type in which a receipt is passed
  // to the customer (email, paper [print]).
  const receivedAs = determineOutputType(receipt.printerId)

  // Omit empty payments (they should not be on the receipt).
  // This is needed in case of multi payment method receipts.
  // The initial payment method could be set to zero.
  const payments = receipt.payments.filter(payment => !payment.amount.eq(0))

  if (receipt.payments.length != payments.length) {
    logWarn("Empty payment constraint: Zero amount filter applied", {
      originalPayments: receipt.payments,
      filteredPayments: payments,
    })
  }

  // Order itemsHistory by its modifiedAt timestamp
  // to make sure that we send it correctly to the backend.
  const itemsHistory = orderBy(
    receipt.itemsHistory,
    historyEntry => historyEntry.modifiedAt,
  )

  // Order cartDiscountsHistory by its createdAt timestamp
  // to make sure that we send it correctly to the backend.
  const cartDiscountsHistory = orderBy(
    receipt.cartDiscountsHistory,
    historyEntry => historyEntry.createdAt,
  )

  return {
    ...receipt,
    payments,
    taxInfos,
    receivedAs,
    totalTaxInfo,
    itemsHistory,
    zeroAndVoidedItems,
    cartDiscountsHistory,
    signatureDeviceFailed: false,
  }
}

function retrieveZeroAndVoidedItems(
  finalItems: OutputInvoiceItem[],
  itemsHistory: InvoiceItem[],
) {
  const itemsToZero = retrieveZeroAmountItems(finalItems)
  const itemsToVoid = retrieveVoidedItems(finalItems, itemsHistory)
  return concat(itemsToZero, itemsToVoid)
}

function retrieveZeroAmountItems(
  items: OutputInvoiceItem[],
): OutputInvoiceItem[] {
  return items.filter(item => item.amount.eq(0))
}

function retrieveVoidedItems(
  finalItems: OutputInvoiceItem[],
  itemsHistory: InvoiceItem[],
): OutputInvoiceItem[] {
  const voidedItems: OutputInvoiceItem[] = []

  // Find the unique item IDs from the history.
  const invoiceItemIds = uniq(
    itemsHistory.map(invoiceItem => invoiceItem.invoiceItemId),
  )

  // For each ID, check the difference between max and final amount.
  for (const invoiceItemId of invoiceItemIds) {
    // At this point there must be a history for this specific item.
    const itemHistory = itemsHistory.filter(
      invoiceItem => invoiceItem.invoiceItemId == invoiceItemId,
    )

    // Therefore, there must be at least one configuration present.
    const lastItemConfiguration = last(itemHistory)!

    // Extract the amount history.
    const itemAmountHistory = itemHistory.map(item => item.amount)

    // Dependent on the last item configuration
    // and its isNegativeAmount property,
    // either retrieve the min OR max amount
    // of the itemHistory for the void evaluation.
    const comparisonAmount =
      (lastItemConfiguration.isNegativeAmount
        ? minOfBigs(itemAmountHistory)
        : maxOfBigs(itemAmountHistory)) ?? Big(0)

    // It is not clear that there is always a final representation
    // of the invoice item since empty/zero items
    // are stripped from the final item list.
    const finalState = finalItems.find(
      invoiceItem => invoiceItem.invoiceItemId == invoiceItemId,
    )

    // In case there is no finalState with its amount present,
    // the item has been zeroed in the cart.
    const finalAmount = finalState?.amount ?? Big(0)

    // The difference is the amount of voided items.
    const voidAmount = comparisonAmount.sub(finalAmount)

    // Do not push/add the current item to the list,
    // in case the voidedAmount equals to zero,
    // because it does not make sense.
    if (voidAmount.eq(0)) continue

    // Transform all item discounts from the last configuration.
    // We solely want to include item discounts here,
    // because we only want to stress position voids.
    // As the cart discount is visually not applied in the shopping cart,
    // and therefore voids are seemingly made on an invoice item basis.
    const discounts = transformDiscountsToDiscountsWithType(
      lastItemConfiguration.discounts,
      "ItemDiscount",
    )

    // Use the last configuration,
    // overwrite the amount and re-evaluate to calculate the correct tax info.
    voidedItems.push(
      evaluateInvoiceItem({
        ...lastItemConfiguration,
        discounts,
        amount: voidAmount,
      }),
    )
  }

  return voidedItems
}

function calculateTaxInfos(
  items: OutputInvoiceItem[],
  roundingDifference?: Big,
) {
  const groupedTaxInfos = groupBy(
    items.map(item => item.taxInfo),
    taxInfo => taxInfo.taxRate,
  )

  const taxInfos = Object.keys(groupedTaxInfos).map(
    taxRate =>
      ({
        ...calculateSharedTaxInfoSums(groupedTaxInfos[taxRate]),
        taxRate: +taxRate,
      }) as TaxInfo,
  )

  const preTotalTaxInfo = calculateSharedTaxInfoSums(taxInfos)
  const totalTaxInfo = {
    ...preTotalTaxInfo,
    correctedGross: preTotalTaxInfo.gross.minus(roundingDifference ?? Big(0)),
  } as TotalTaxInfo

  return { taxInfos, totalTaxInfo }
}

function calculateSharedTaxInfoSums(
  taxInfos: TaxInfo[],
): Omit<TotalTaxInfo, "correctedGross"> {
  const tax = sumBigArray(taxInfos.map(info => info.tax))
  const net = sumBigArray(taxInfos.map(info => info.net))
  const gross = sumBigArray(taxInfos.map(info => info.gross))
  const discount = sumBigArray(taxInfos.map(info => info.discount))
  const undiscountedGross = sumBigArray(
    taxInfos.map(info => info.undiscountedGross),
  )

  return { net, tax, gross, discount, undiscountedGross }
}

// Frontend to GraphQL transformation functions

export function transformOutputReceiptToGraphQL(
  receipt: OutputReceipt,
  mainGroups: Record<string, MainGroup>,
  articleGroups: Record<string, ArticleGroup>,
): ReceiptInput {
  return {
    receiptId: receipt.receiptId,
    company: receipt.company,
    operator: receipt.operator,
    createdAt: receipt.createdAt,
    department: receipt.department,
    receivedAs: receipt.receivedAs,
    receiptText: receipt.receiptText,
    cashRegister: receipt.cashRegister,
    receiptNumber: receipt.receiptNumber,
    billingAddress: receipt.billingAddress,
    gpTomReceiptData: receipt.gpTomResponse,
    hobexReceiptData: receipt.hobexResponse,
    shippingAddress: receipt.shippingAddress,
    items: transformOutputInvoiceItemsToGraphQL(
      receipt.items,
      mainGroups,
      articleGroups,
    ),
    zeroAndVoidedItems: transformOutputInvoiceItemsToGraphQL(
      receipt.zeroAndVoidedItems,
      mainGroups,
      articleGroups,
    ),
    itemsHistory: transformInvoiceItemHistoryToGraphQL(
      receipt.itemsHistory,
      mainGroups,
      articleGroups,
    ),
    cartDiscountsHistory: receipt.cartDiscountsHistory.map(historyEntry => ({
      createdAt: formatIsoDateTime(historyEntry.createdAt),
      discounts: historyEntry.discounts.map(transformDiscountToGraphQL),
    })),
    payments: receipt.payments.map(transformValueTransferToGraphQL),
    payouts: receipt.payouts?.map(transformValueTransferToGraphQL),
    tips: receipt.tips?.map(transformTipTransferToGraphQL),
    taxInfos: receipt.taxInfos.map(taxInfo => ({
      taxRate: taxInfo.taxRate,
      ...transformTaxInfoToGraphQL(taxInfo),
    })),
    totalTaxInfo: {
      correctedGross: bigToEuros(receipt.totalTaxInfo.correctedGross),
      ...transformTaxInfoToGraphQL(receipt.totalTaxInfo),
    },
  }
}

function transformOutputInvoiceItemsToGraphQL(
  items: OutputInvoiceItem[],
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
): InvoiceItemInput[] {
  return items.map(item => ({
    invoiceItemId: item.invoiceItemId,
    amount: item.amount.toNumber(),
    isDiscountBlocked: item.isDiscountBlocked,
    createdAt: item.createdAt.toISOString(),
    modifiedAt: item.modifiedAt.toISOString(),
    article: transformInvoiceItemArticleToGraphQL(
      item.article,
      mainGroups,
      articleGroups,
    ),
    discounts: item.discounts.map(discount => ({
      name: discount.name,
      initialType: discount.initialType,
      discountType: discount.discountType,
      percent: discount.percent?.toNumber(),
      absolute: bigToEuros(discount.absolute),
    })),
    additionalText: item.additionalText,
    discountedPricePerUnit: bigToEuros(
      item.discountedPricePerUnit ?? item.article.price,
    ),
    taxInfo: transformTaxInfoToGraphQL(item.taxInfo),
  }))
}

function transformInvoiceItemHistoryToGraphQL(
  items: InvoiceItem[],
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
): HistoricInvoiceItemInput[] {
  return items.map(item => ({
    invoiceItemId: item.invoiceItemId,
    amount: item.amount.toNumber(),
    additionalText: item.additionalText,
    isDiscountBlocked: item.isDiscountBlocked,
    modifiedAt: item.modifiedAt.toISOString(),
    article: transformInvoiceItemArticleToGraphQL(
      item.article,
      mainGroups,
      articleGroups,
    ),
    discounts: item.discounts.map(transformDiscountToGraphQL),
  }))
}

function transformInvoiceItemArticleToGraphQL(
  article: ItemArticle,
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
): ArticleInput {
  return {
    mainGroupId: article.mainGroupId,
    articleGroupId: article.articleGroupId,
    articleId: article.articleId,
    name: article.name,
    number: article.number,
    taxRate: article.taxRate,
    price: bigToEuros(article.price),
    mainGroupName:
      mainGroups[article.mainGroupId]?.name ?? t_General().tGeneral("unknown"),
    articleGroupName:
      articleGroups[article.articleGroupId]?.name ??
      t_General().tGeneral("unknown"),
  }
}

function transformDiscountToGraphQL(discount: Discount): DiscountInput {
  return {
    name: discount.name,
    absolute: bigToEuros(discount.absolute),
    percent: discount.percent?.toNumber(),
  }
}

function transformValueTransferToGraphQL(
  transfer: ValueTransfer,
): ValueTransferInput {
  return {
    paymentMethodId: transfer.paymentMethodId,
    moneyAmount: bigToEuros(transfer.amount),
    paymentMethodName: transfer.methodName,
    flags: transfer.flags,
  }
}

function transformTipAttributionToGraphQL(
  transfer: TipAttribution,
): TipAttributionInput {
  return {
    paymentMethodId: transfer.paymentMethodId,
    paymentMethodName: transfer.methodName,
    flags: transfer.flags,
  }
}

function transformTipTransferToGraphQL(
  transfer: TipTransfer,
): TipTransferInput {
  return {
    moneyAmount: bigToEuros(transfer.amount),
    originateFrom: transformTipAttributionToGraphQL(transfer.originateFrom),
    considerFor: transfer.considerFor
      ? transformTipAttributionToGraphQL(transfer.considerFor)
      : undefined,
  }
}

function transformTaxInfoToGraphQL(taxInfo: Omit<TaxInfo, "taxRate">) {
  return {
    net: bigToEuros(taxInfo.net),
    tax: bigToEuros(taxInfo.tax),
    gross: bigToEuros(taxInfo.gross),
    discount: bigToEuros(taxInfo.discount),
    undiscountedGross: bigToEuros(taxInfo.undiscountedGross),
  }
}

// GraphQL to frontend transformation functions

export function transformFromGraphQLToDetailedReceiptItem(
  item: DetailedReceiptItemFragment,
): DetailedArticleReceiptItem {
  return {
    article: {
      mainGroupId: item.initialArticle.mainGroupId,
      articleGroupId: item.initialArticle.articleGroupId,
      articleId: item.initialArticle.articleId,
      name: item.initialArticle.name,
      taxRate: item.initialArticle.taxRate,
      price: eurosToBig(item.initialArticle.price),
      number: item.initialArticle.number,
    },
    ...transformFromGraphQLToSharedReceiptItem(item),
  }
}

function retrieveFlagsForTransfer(
  paymentMethods: Record<PaymentMethodId, PaymentMethod>,
  paymentMethodId: PaymentMethodId,
  inputFlags?: ValueTransferFlagsFragment,
) {
  if (inputFlags) return inputFlags

  const paymentMethod = paymentMethods?.[paymentMethodId]

  if (!paymentMethod)
    throw Error("retrieveFlagsForTransfer - Payment method missing")

  return {
    ...paymentMethod.properties,
    ...paymentMethod.programmaticProperties,
  }
}

export function transformFromGraphQLToValueTransfers(
  valueTransfers: ValueTransferFragment[],
  paymentMethods: Record<PaymentMethodId, PaymentMethod>,
): ValueTransfer[] {
  return valueTransfers.map(transfer => ({
    paymentMethodId: transfer.paymentMethodId,
    methodName: transfer.paymentMethodName,
    amount: eurosToBig(transfer.moneyAmount),
    flags: retrieveFlagsForTransfer(
      paymentMethods,
      transfer.paymentMethodId,
      transfer.flags,
    ),
  }))
}

function transformFromGraphQLToTipAttribution(
  tipAttribution: TipAttributionFragment,
  paymentMethods: Record<PaymentMethodId, PaymentMethod>,
): TipAttribution {
  return {
    paymentMethodId: tipAttribution.paymentMethodId,
    methodName: tipAttribution.paymentMethodName,
    flags: retrieveFlagsForTransfer(
      paymentMethods,
      tipAttribution.paymentMethodId,
      tipAttribution.flags,
    ),
  }
}

export function transformFromGraphQLToTipTransfers(
  tipTransfers: TipTransferFragment[],
  paymentMethods: Record<PaymentMethodId, PaymentMethod>,
): TipTransfer[] {
  return tipTransfers.map(transfer => ({
    amount: eurosToBig(transfer.moneyAmount),
    originateFrom: transformFromGraphQLToTipAttribution(
      transfer.originateFrom,
      paymentMethods,
    ),
    considerFor: transfer.considerFor
      ? transformFromGraphQLToTipAttribution(
          transfer.considerFor,
          paymentMethods,
        )
      : undefined,
  }))
}
