import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { DiscountType, InitialType } from "../api/graphql/types"

export type DiscountId = string

type DiscountNameBaseData = { name?: string }
type DiscountBaseData = { absolute?: Big; percent?: Big }

export type Discount = DiscountNameBaseData & DiscountBaseData

export type PredefinedDiscount = DiscountBaseData & {
  discountId: DiscountId
  name: string
}

export type CompleteDiscount = DiscountNameBaseData & {
  absolute: Big
  percent: Big
  initialType: InitialType

  discountType?: DiscountType
}

export type CartDiscountHistoryEntry = {
  createdAt: Date
  discounts: Discount[]
}

export const DiscountEntity = createEntityFor<Discount>()
  .withSchema(
    z.object({
      name: z.ostring(),
      absolute: z.ostring(),
      percent: z.ostring(),
    }),
  )
  .serialize(data => ({
    ...data,
    absolute: data.absolute?.toString(),
    percent: data.percent?.toString(),
  }))
  .deserialize(json => ({
    ...json,
    absolute: json.absolute ? Big(json.absolute) : undefined,
    percent: json.percent ? Big(json.percent) : undefined,
  }))

export const CartDiscountHistoryEntryEntity =
  createEntityFor<CartDiscountHistoryEntry>()
    .withSchema(
      z.object({
        createdAt: z.string(),
        discounts: z.array(DiscountEntity.schema),
      }),
    )
    .serialize(data => ({
      discounts: data.discounts.map(DiscountEntity.serialize),
      createdAt: data.createdAt.toISOString(),
    }))
    .deserialize(json => ({
      discounts: json.discounts?.map(DiscountEntity.deserialize) ?? [],
      createdAt: new Date(json.createdAt),
    }))
