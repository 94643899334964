import React, { ReactNode } from "react"
import { useRecoilState } from "recoil"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { DateTimeInput } from "../../input/field/DateTimeInput"
import {
  dateTimePickerVisibilityState,
  useDateTimePickerConfig,
} from "./InternalDateTimeContext"

// Add empty translation strings for the following properties
// in order to remove them from the UI.
const LOCALE_TEXT = {
  datePickerToolbarTitle: "",
  timePickerToolbarTitle: "",
  dateTimePickerToolbarTitle: "",
  dateRangePickerToolbarTitle: "",
}

// For now, always use the German date format
// to reduce required adaptations and bloating up logic further.
const DATE_FORMATS = {
  shortDate: "dd. MMM",
  normalDate: "EEE, dd. MMM yyyy",
  normalDateWithWeekday: "EEE, dd. MMM yyyy",
}

export function DateTimePickerProvider({ children }: { children: ReactNode }) {
  return (
    <PickerLocalisationProvider>
      <DateTimePickerOverlay />
      {children}
    </PickerLocalisationProvider>
  )
}

function PickerLocalisationProvider({ children }: { children: ReactNode }) {
  return (
    <LocalizationProvider
      localeText={LOCALE_TEXT}
      dateFormats={DATE_FORMATS}
      dateAdapter={AdapterDateFns}
    >
      {children}
    </LocalizationProvider>
  )
}

function DateTimePickerOverlay() {
  const config = useDateTimePickerConfig()
  const [isVisible, setIsVisible] = useRecoilState(
    dateTimePickerVisibilityState,
  )

  if (!config) return null

  const onClose = () => setIsVisible(false)

  return (
    <DateTimeInput
      {...config}
      overlayMode
      visible={isVisible}
      onClose={onClose}
    />
  )
}
