import React, { ReactElement } from "react"

import { Icon } from "../../display/Icon"
import { MCIcon } from "../../display/MCIcon"
import { ToolTipIcon } from "../../display/ToolTipIcon"
import { Row } from "../../layout/FlexBox"
import { Color, TextSize } from "../../types"
import { Label } from "../../typography/Label"

type IconConfig = {
  name?: MCIcon
  color?: Color
  size?: "XS" | "S" | "M" | "L" | "XL"
}

type SelectorInputProps = SelectorProps & {
  toolTip?: string
  icon?: IconConfig
  fontSize?: TextSize

  // For convenience reasons we pass a
  // possible error message to the input component
  // It has no other use than displaying an
  // error state (message is not shown in here)
  errorMessage?: string

  label?: ReactElement | string
}

export { CheckBoxInput, RadioButtonInput }

function CheckBoxInput(props: Omit<SelectorInputProps, "type">) {
  return <SelectorInput {...props} type="checkBox" />
}

function RadioButtonInput(props: Omit<SelectorInputProps, "type">) {
  return <SelectorInput {...props} type="radioButton" />
}

function SelectorInput(props: SelectorInputProps) {
  const { toolTip, disabled, fontSize, errorMessage } = props

  const selector = (
    <Selector {...props} errorIndicator={errorMessage != undefined} />
  )

  let icon
  if (toolTip) icon = <ToolTipIcon text={toolTip} {...props.icon} />
  else if (props.icon) icon = <Icon name="information" {...props.icon} />

  const label =
    typeof props.label == "string" ? (
      <Label
        text={props.label}
        disabled={disabled}
        {...{
          h1: fontSize == "h1",
          h2: fontSize == "h2",
          h3: fontSize == "h3",
          h4: fontSize == "h4",
          h5: fontSize == "h5",
          small: fontSize == "small",
          medium: fontSize == "medium",
          default: fontSize == "default",
        }}
      />
    ) : (
      props.label
    )

  return (
    <Row alignCenter gap="XXXS">
      {selector}
      {label}
      {icon}
    </Row>
  )
}

type SelectorProps = {
  value: boolean
  type: "checkBox" | "radioButton"
  onChange: (value: boolean) => void

  disabled?: boolean
  errorIndicator?: boolean
}

function Selector(props: SelectorProps) {
  const { type, value, errorIndicator, onChange } = props

  let icon: MCIcon
  if (type == "radioButton") {
    icon = value ? "radiobox-marked" : "radiobox-blank"
  } else icon = value ? "checkbox-marked-outline" : "checkbox-blank-outline"

  const onPress = () => onChange(!value)

  return (
    <Icon
      name={icon}
      disabled={props.disabled ?? false}
      color={errorIndicator ? "error" : undefined}
      onPress={onPress}
    />
  )
}
