import Big from "big.js"
import { orderBy, reduce } from "lodash"
import { atom } from "recoil"

import { clamp, minOfBigs } from "~shared/lib/Big"
import { selector } from "~shared/lib/recoil/lib"
import { PaymentMethod, TipTransfer, ValueTransfer } from "~shared/types"

import { cardDeviceState } from "../CashRegister/state"
import { gpTomConnectionState } from "../GPtom/state"
import { hobexConnectionState } from "../Hobex/state"
import {
  paymentMethodRecordSelector,
  paymentMethodsState,
} from "../OfflineData/state"
import {
  cartItemsWithAllDiscountsTotalSelector,
  currentInvoiceContactTextSelector,
} from "../ShoppingCart/state"
import { transformPaymentMethodToValueTransferFlags } from "./lib"

// Determines the currently available payment methods.
export const paymentMethodsSelector = selector({
  key: "paymentMethods",
  get: ({ get }) => {
    const cardDevice = get(cardDeviceState)

    return get(paymentMethodsState).filter(({ properties }) => {
      // The payment method must be usable as a payment method.
      if (!properties.usableForPayment) return false

      // In case of the payment method being specified
      // as a card payment method and there is no card device present,
      // do not show the card payment method in general
      // (payment buttons in ShoppingCartScreen, payout buttons in PaymentScreen, etc.).
      return !(properties.isCardPayment && cardDevice == "None")
    })
  },
})

// Determines the currently available payout methods
// (in this context payout means either overpay or refund/actual payout)
// There will not be payout methods on the receipt
// when there is no return money/change.
export const payoutMethodsSelector = selector({
  key: "payoutMethods",
  get: ({ get }) => {
    const cardDevice = get(cardDeviceState)
    const isPayout = get(isPayoutModeSelector)
    const isOverpay = get(isOverpayModeSelector)
    const allPaymentMethods = get(paymentMethodsState)

    // Only Hobex ViA (TecsClient), Hobex ZVT (ZVTClient) or
    // a possible external device without a specific integration
    // in our app, should be allowed to execute card payouts.
    // (GP tom does not support refunds/payouts).
    const isCardDeviceNotPayoutCapable = !(
      cardDevice == "Hobex" ||
      cardDevice == "ZVT" ||
      cardDevice == "External"
    )

    const payoutMethods = allPaymentMethods.filter(({ properties }) => {
      // The payment method must be usable as a payout method.
      // AND
      // We do not want to allow card payout transactions,
      // when we are in overpay mode without an actual payout,
      // because we cannot simply return money that way,
      // and it would not make much sense.
      if (
        !properties.usableForPayout ||
        (properties.isCardPayment && isOverpay && !isPayout)
      )
        return false

      // In case of the payment method being specified
      // as a card payment method and there is no card device present,
      // do not show the card payment method in general
      // (payment buttons in ShoppingCartScreen, payout buttons in PaymentScreen, etc.).
      return !(properties.isCardPayment && isCardDeviceNotPayoutCapable)
    })

    // Show all beforehand determined payoutMethods,
    // when we are in actual payout mode (not overpay mode) or
    // the only payment method available
    // is the cash (Bar) payment method.
    //
    // Otherwise,
    // do not provide any payout method
    // for a possible overpay procedure,
    // as it would not make any sense
    // (other than in the context of cash transactions).
    return isPayout ||
      !(
        allPaymentMethods.length == 1 &&
        !allPaymentMethods[0].programmaticProperties.affectsCashTransfers
      )
      ? payoutMethods
      : []
  },
})

// Payments used for handling the active invoice
// (may not represent the payments included in the final receipt -
//  see outputPaymentsSelector).
export const inputPaymentsState = atom({
  key: "inputPayments",
  default: [] as ValueTransfer[],
})

// Evaluates the payment transfer(s) of the active invoice
// that should be included in the receipt.
// In case there is/are no tip(s) present,
// the result of the selector will be equal
// to the one of inputPaymentsState.
export const outputPaymentsSelector = selector({
  key: "outputPayments",
  get: ({ get }) => {
    const inputPayments = get(inputPaymentsState)
    let remainingTipsTotal = get(tipsTotalSelector)

    // No tip(s) need to be considered,
    // therefore just return the untouched version of inputPayments.
    if (remainingTipsTotal.lte(0)) return inputPayments

    // Otherwise, tip(s) must be considered.
    // Therefore, extend the received payments with
    // their respective index (position in PaymentScreen) AND
    // flag them with whether they are origins for tip amounts or not.
    const paymentMethodRecord = get(paymentMethodRecordSelector)
    const extendedPayments = orderBy(
      inputPayments.map((payment, index) => ({
        ...payment,
        index: index as number | undefined,
        originOfTip: (paymentMethodRecord?.[payment.paymentMethodId]
          .programmaticProperties.originOfTip == true) as boolean | undefined,
      })),

      // Sort the extended payments first by their
      // origin and after that by their index property (render order),
      // to make sure that the upcoming sanitization process
      // is carried out in the correct/destined manner.
      payment => [!payment.originOfTip, payment.index],
    )

    // Sanitize the extended payments in order to handle
    // a tipping event in an optimal manner for the entrepreneur
    // (e.g. card tipping - transaction fee optimisation,
    //  since disagio applies only to the regular transaction amount and
    //  not to any potential tips, minimize the regular transaction amount).
    const sanitizedPayments = extendedPayments.map(payment => {
      // 1. Retrieve the tip amount that should be
      // subtracted from the entered payment amount and
      // make sure to not subtract more than the payment entry holds.
      let tipAmount =
        minOfBigs([payment.amount, remainingTipsTotal]) ?? Big(0.0)
      let sanitizedAmount = payment.amount.minus(tipAmount)

      // 2. In case the payment entry is an origin of tip and
      // the amount it holds is lower or equal to zero,
      // make sure that it at least holds one cent.
      //
      // NOTE: Important for tip(s) via card payment,
      // as otherwise no tipping would be possible
      // as zero value card transactions are not possible.
      if (payment.originOfTip && sanitizedAmount.lte(0)) {
        // 2.1. In that case, reduce the targeted tip amount by one cent.
        tipAmount = clamp(tipAmount.minus(0.01), Big(0))

        // 2.2. Subtract the new tip from the original payment amount.
        sanitizedAmount = payment.amount.minus(tipAmount)
      }

      // 3. Subtract the handled tip amount (share)
      // from the remaining tips total amount.
      remainingTipsTotal = remainingTipsTotal.minus(tipAmount)

      // 4. The new amount of the payment entry represents
      // the regular transaction value without included tip(s).
      return { ...payment, amount: sanitizedAmount }
    })

    // After sanitizing the extended payments,
    // apply the original sort order to the payment entries and
    // retract the applied extensions.
    return orderBy(sanitizedPayments, payment => payment.index).map(payment => {
      delete payment.index
      delete payment.originOfTip

      return payment
    })
  },
})

// The total sum of all card payments
// (backed by a card payment method).
const cardPaymentsTotalSelector = selector({
  key: "cardPaymentsTotal",
  get: ({ get }) => {
    let amountTotal = Big(0)
    const payments = get(outputPaymentsSelector)

    if (payments.length <= 0) return amountTotal

    const paymentMethodRecord = get(paymentMethodRecordSelector)

    for (const { paymentMethodId, amount } of payments) {
      const paymentMethod = paymentMethodRecord?.[paymentMethodId]

      if (!paymentMethod) continue
      if (!paymentMethod.properties.isCardPayment) continue

      amountTotal = amountTotal.plus(amount)
    }

    return amountTotal
  },
})

// The payment amount, which will be sent to the Hobex API (via purchase).
export const hobexPaymentAmountSelector = selector({
  key: "hobexPaymentAmount",
  get: ({ get }) =>
    get(hobexConnectionState) == "not used"
      ? Big(0)
      : get(cardPaymentsTotalSelector),
})

// The payment amount, which will be sent to the GP tom API (via sale).
export const gpTomPaymentAmountSelector = selector({
  key: "gpTomPaymentAmount",
  get: ({ get }) =>
    get(gpTomConnectionState) == "not used"
      ? Big(0)
      : get(cardPaymentsTotalSelector),
})

// The current total amount that is paid for the active invoice.
const moneyAmountPaidSelector = selector({
  key: "moneyAmountPaid",
  get: ({ get }) =>
    reduce(
      get(inputPaymentsState),
      (acc, payment) => acc.plus(payment.amount),
      Big(0),
    ),
})

// The money amount that the final customer needs to pay
// (could be negative in case of overpay/tip or payout).
export const moneyAmountDueSelector = selector({
  key: "moneyAmountDue",
  get: ({ get }) => {
    const paid = get(moneyAmountPaidSelector)

    return Big(
      get(cartItemsWithAllDiscountsTotalSelector)
        .minus(paid.lt(0) ? Big(0) : paid)
        .round(2)
        .toFixed(2),
    )
  },
})

// The payment method from which a potential tip should originate from.
//
// NOTE: Currently, only one payment method can have this flag set to true.
const originOfTipPaymentMethodSelector = selector<PaymentMethod | undefined>({
  key: "originOfTipPaymentMethod",
  get: ({ get }) =>
    get(paymentMethodsState).filter(
      method => method.programmaticProperties.originOfTip,
    )?.[0],
})

// The payment method to which the tip should be attributed to/considered for.
//
// NOTE: Currently, only one payment method can have this flag set to true.
export const attributionForTipPaymentMethodSelector = selector<
  PaymentMethod | undefined
>({
  key: "attributionForTipPaymentMethod",
  get: ({ get }) =>
    get(paymentMethodsState).filter(
      method => method.programmaticProperties.attributionForTip,
    )?.[0],
})

// States whether the active invoice can have or
// rather should evaluate a potential tip amount.
const isTipCapableSelector = selector({
  key: "isTipCapable",
  get: ({ get }) => {
    // In case of a zero or negative invoice total,
    // we can shortcut the evaluation by simply returning false.
    if (get(cartItemsWithAllDiscountsTotalSelector).lte(0)) return false

    const tipOriginMethod = get(originOfTipPaymentMethodSelector)
    const tipOriginInputPayment = get(inputPaymentsState).find(
      payment => payment.paymentMethodId == tipOriginMethod?.paymentMethodId,
    )

    // In case the tip origin method is included
    // in the current invoice input payments AND
    // the related value is greater than zero,
    // tip(s) can be awarded through and for the operator.
    return (
      tipOriginInputPayment != undefined && tipOriginInputPayment.amount.gt(0)
    )
  },
})

// Evaluates the tip transfer(s) of the active invoice.
export const tipsSelector = selector<TipTransfer[]>({
  key: "tips",
  get: ({ get }) => {
    // In the event that tip(s) cannot be awarded right now,
    // simply return an empty array.
    if (!get(isTipCapableSelector)) return []

    const due = get(moneyAmountDueSelector)
    const origin = get(originOfTipPaymentMethodSelector)
    const attribution = get(attributionForTipPaymentMethodSelector)

    // Again, make sure that there is
    // an origin payment method and a valid tip amount present.
    // Then, construct the tip array accordingly.
    return origin && due.lt(0)
      ? [
          {
            amount: due.times(-1),

            originateFrom: {
              paymentMethodId: origin.paymentMethodId,
              methodName: origin.methodName,
              flags: transformPaymentMethodToValueTransferFlags(origin),
            },

            // Other than the origin tip attribution,
            // the considerFor one does not need to be defined.
            // In that case, a consideration/attribution in the context
            // of XBillingReports will not be carried out.
            considerFor: attribution
              ? {
                  paymentMethodId: attribution.paymentMethodId,
                  methodName: attribution.methodName,
                  flags:
                    transformPaymentMethodToValueTransferFlags(attribution),
                }
              : undefined,
          },
        ]
      : []
  },
})

// The total of all tips included in the active invoice
// (independent of their respective origin).
export const tipsTotalSelector = selector({
  key: "tipsTotal",
  get: ({ get }) =>
    reduce(get(tipsSelector), (acc, tip) => acc.plus(tip.amount), Big(0)),
})

// The total sum of all card originating tips
// (backed by an origin card payment method).
//
// NOTE: Currently, the result of this selector
// will be the same as the one of tipsTotalSelector.
const cardTipsTotalSelector = selector({
  key: "cardTipsTotal",
  get: ({ get }) => {
    let amountTotal = Big(0)
    const tips = get(tipsSelector)

    if (tips.length <= 0) return amountTotal

    const paymentMethodRecord = get(paymentMethodRecordSelector)

    for (const { originateFrom, amount } of tips) {
      const paymentMethod = paymentMethodRecord?.[originateFrom.paymentMethodId]

      if (!paymentMethod) continue
      if (!paymentMethod.properties.isCardPayment) continue

      amountTotal = amountTotal.plus(amount)
    }

    return amountTotal
  },
})

// The tip amount, which will be sent to the Hobex API (via purchase).
export const hobexTipAmountSelector = selector({
  key: "hobexTipAmount",
  get: ({ get }) =>
    get(hobexConnectionState) == "not used"
      ? Big(0)
      : get(cardTipsTotalSelector),
})

// The tip amount, which will be sent to the GP tom API (via sale).
export const gpTomTipAmountSelector = selector({
  key: "gpTomTipAmount",
  get: ({ get }) =>
    get(gpTomConnectionState) == "not used"
      ? Big(0)
      : get(cardTipsTotalSelector),
})

// Trys to safely access the first usable
// payout method of all available payment methods.
// In case there is no payout method available,
// it will result in undefined.
const initialPayoutMethodSelector = selector<PaymentMethod | undefined>({
  key: "initialPayoutMethod",
  get: ({ get }) => get(payoutMethodsSelector)?.[0],
})

// As the payout method can be changed
// by the user within the payment screen,
// a state initialised with the first
// usable payout method is required.
export const payoutMethodState = atom<PaymentMethod | undefined>({
  key: "payoutMethod",
  default: initialPayoutMethodSelector,
})

// Determines whether there is a payout method available or not.
const isPayoutMethodAvailableSelector = selector({
  key: "isPayoutMethodAvailable",
  get: ({ get }) => get(payoutMethodState) != undefined,
})

// Determines the payout amount (overpay or actual payout)
// of the active invoice.
const payoutAmountSelector = selector({
  key: "payoutAmount",
  get: ({ get }) => {
    const due = get(moneyAmountDueSelector)
    const tipTotal = get(tipsTotalSelector)

    // A non-zero payout amount should only be present
    // when there is no positive tip amount AND
    // there is less due than zero (indicating overpay or actual payout).
    return tipTotal.lte(0) && due.lt(0) ? due.times(-1) : Big(0)
  },
})

// Evaluates the payout transfer(s) of the active invoice.
export const payoutsSelector = selector<ValueTransfer[]>({
  key: "payouts",
  get: ({ get }) => {
    const payoutMethod = get(payoutMethodState)
    const payoutAmount = get(payoutAmountSelector)

    // Make sure that there is
    // a payout method and a valid amount present.
    // Then, construct the payout array accordingly.
    return payoutMethod && payoutAmount.gt(0)
      ? [
          {
            amount: payoutAmount,
            methodName: payoutMethod.methodName,
            paymentMethodId: payoutMethod.paymentMethodId,
            flags: transformPaymentMethodToValueTransferFlags(payoutMethod),
          },
        ]
      : []
  },
})

// The amount of money that should be refunded
// to the final customer using the Hobex API (via refund).
export const hobexPayoutAmountSelector = selector({
  key: "hobexPayoutAmount",
  get: ({ get }) => {
    const payoutMethod = get(payoutMethodState)
    const payoutAmount = get(payoutAmountSelector)

    if (
      // If the cash register is not configured to use the Hobex integration OR
      get(hobexConnectionState) == "not used" ||
      // ...if there is nothing to pay out OR
      payoutAmount.lte(0) ||
      // ...if there is no payout method available OR
      !payoutMethod ||
      // ...if the payout method is not a card payment method...
      !payoutMethod.properties.isCardPayment
    )
      // ...then nothing should be refunded via Hobex.
      return Big(0)

    // Otherwise, the payout amount should be refunded.
    return payoutAmount
  },
})

// Contact related additional text for the active invoice (later receipt).
export const receiptTextState = atom({
  key: "receiptText",
  default: currentInvoiceContactTextSelector,
})

// Evaluates whether the final customer
// needs to pay for products (with or without tip) and/or
// receives funds (overpay or actual payout) from the company.
export const paymentModeSelector = selector({
  key: "paymentMode",
  get: ({ get }) => {
    const due = get(moneyAmountDueSelector)

    if (due.gt(0)) return "waiting for payment"

    if (due.lt(0))
      return get(tipsTotalSelector).gt(0)
        ? "waiting for payment with tip"
        : "waiting for payout"

    return "payment finished"
  },
})

// Determines whether the card payment should be carried out
// as a Hobex, GP tom or general transaction.
export const cardPaymentModeSelector = selector({
  key: "cardPaymentMode",
  get: ({ get }) => {
    if (get(gpTomPaymentAmountSelector).gt(0))
      return "waiting for gp tom payment"

    if (get(hobexPaymentAmountSelector).gt(0))
      return "waiting for hobex payment"

    return get(hobexPayoutAmountSelector).gt(0)
      ? "waiting for hobex payout"
      : undefined
  },
})

// States whether the active invoice is in overpay mode or not
// (this does not rule out that we are in actual payout mode too).
const isOverpayModeSelector = selector({
  key: "isOverpayMode",
  get: ({ get }) => get(moneyAmountDueSelector).lt(0),
})

// States whether the active invoice is in actual payout mode or not.
export const isPayoutModeSelector = selector({
  key: "isPayoutMode",
  get: ({ get }) => get(cartItemsWithAllDiscountsTotalSelector).lt(0),
})

// Payment methods that do not have the property overpayAllowed enabled
// are not allowed to contain an amount
// greater than the total of the invoice (all of those payments together).
// Also, when there is no payout method available and
// an overpay is applied, a violation needs to be displayed.
export const isOverpayConstraintViolatedSelector = selector({
  key: "isOverpayConstraintViolated",
  get: ({ get }) => {
    // If there is no payout method available
    // to which a potential overpay/actual payout can be paid out to AND
    // the invoice is in an active overpay mode,
    // we can shortcut the evaluation by returning true.
    if (!get(isPayoutMethodAvailableSelector) && get(isOverpayModeSelector))
      return true

    const cartTotalWithAllDiscounts = get(
      cartItemsWithAllDiscountsTotalSelector,
    )

    // In case of an actual payout, we can shortcut the evaluation
    // by simply returning false (at this point).
    if (cartTotalWithAllDiscounts.lt(0)) return false

    // Otherwise, evaluate whether payments that are not backed
    // by a truthful overpay or originOfTip flag
    // are included in the active payment process and
    // exceed and therefore overpay the total of the active invoice.

    const inputPayments = get(inputPaymentsState)
    const paymentMethodRecord = get(paymentMethodRecordSelector)
    let remainingCartTotal = cartTotalWithAllDiscounts

    for (const payment of inputPayments) {
      const paymentMethod = paymentMethodRecord?.[payment.paymentMethodId]

      // Skip the subtraction step when...
      if (
        // ...the current method is not defined/cannot
        // be found in the general paymentMethodRecord OR
        !paymentMethod ||
        // ...the current payment method is allowed to be used for overpay OR
        paymentMethod.properties.overpayAllowed ||
        // ... the current payment method is used as a tip origin.
        paymentMethod.programmaticProperties.originOfTip
      )
        continue

      remainingCartTotal = remainingCartTotal.minus(payment.amount)
    }

    // In case the remaining total of the cart is lower than zero,
    // an overpay violation needs to be reported
    // as the total of the invoice has been exceeded.
    return remainingCartTotal.lt(0)
  },
})

// Determines whether the invoice/receipt can be submitted or not.
export const isReceiptReadyToSubmitSelector = selector({
  key: "isReceiptReadyToSubmit",
  get: ({ get }) =>
    // The invoice/receipt is ready to submit when:
    // The total of the invoice/receipt is fully paid for AND
    // the overpay constraint is not violated.
    get(moneyAmountDueSelector).lte(0) &&
    !get(isOverpayConstraintViolatedSelector),
})
