import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type PrinterId = string

export type ReceiptPrinter = {
  printerId: PrinterId
  deviceName: string
  displayName: string
  hasCutter: boolean
  printingWidth: number

  bluetoothDeviceId?: string | null
}

export const ReceiptPrinterEntity = createEntityFor<ReceiptPrinter>()
  .withSchema(
    z.object({
      printerId: z.string(),
      deviceName: z.string(),
      displayName: z.string(),
      hasCutter: z.boolean(),
      printingWidth: z.number(),
      bluetoothDeviceId: z.string().optional().nullable(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
