import { retrieveSpacingPixels } from "../layout/Box"
import { useTheme } from "../theme"
import { ResponsiveSpacing } from "../types"
import { useScreenSizeType } from "./useScreenSizeType"

export type GapOptions = {
  // If `rowGap` or `columnGap` is set in addition to that property,
  // the more specific gap definition will be considered.
  gap?: ResponsiveSpacing

  rowGap?: ResponsiveSpacing
  columnGap?: ResponsiveSpacing
}

export function useGapping(opts: GapOptions) {
  const theme = useTheme()
  const screenSizeType = useScreenSizeType()

  const rowGap =
    opts.rowGap || opts.gap
      ? retrieveSpacingPixels(
          theme,
          screenSizeType,
          opts.rowGap ?? opts.gap ?? "none",
        )
      : undefined

  const columnGap =
    opts.columnGap || opts.gap
      ? retrieveSpacingPixels(
          theme,
          screenSizeType,
          opts.columnGap ?? opts.gap ?? "none",
        )
      : undefined

  return { rowGap, columnGap }
}
