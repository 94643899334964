import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type CashRegisterId = string

export type CashRegisterData = { cashRegisterId: CashRegisterId; name: string }

export const CashRegisterDataEntity = createEntityFor<CashRegisterData>()
  .withSchema(z.object({ cashRegisterId: z.string(), name: z.string() }))
  .serialize(data => data)
  .deserialize(json => json)
