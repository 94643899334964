import React, { ReactNode } from "react"
import {
  LayoutChangeEvent,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native"

import { PressableOpacity } from "../button/PressableOpacity"
import { ScreenSizeType, useScreenSizeType } from "../hooks/useScreenSizeType"
import { Theme, useTheme } from "../theme"
import { BorderRadius, ResponsiveSpacing } from "../types"

// Style props, which could also be applied
// to other components (via 'useBoxStyle' hook).
export type BoxStyleProps = Pick<ViewProps, "pointerEvents"> & {
  // A Box can grow to fill the available space (flex: 1)...
  expand?: boolean

  // ...or specifically flex to a certain value.
  flex?: number

  // Children of the Box can be centered.
  // Dependent on the 'flexDirection' (main axis) the center alignment
  // is applied vertically (row) or horizontally (column).
  alignCenter?: boolean

  // A Box can have a border color...
  borderColor?: string

  // ...and a border radius.
  borderRadius?: BorderRadius

  // If set to true, applies an opacity value to the Box
  // that makes it appear to be not usable/pressable.
  // In case an 'onPress' handler is passed, it will not fire either.
  disabled?: boolean

  // A Box can be elevated and therefore cast a shadow.
  elevation?: number

  // A Box can have a background color.
  backgroundColor?: string

  // A Box can have an inner spacing (padding).

  // When set, all-side padding is only applied
  // to sides where no specific padding value is passed.
  pad?: ResponsiveSpacing

  // When set, overwrites all other pad values.
  padTop?: ResponsiveSpacing
  padLeft?: ResponsiveSpacing
  padRight?: ResponsiveSpacing
  padBottom?: ResponsiveSpacing

  // When set, vertical and/or horizontal padding
  // is only applied to sides where no
  // left/right and/or top/bottom padding is passed.
  padVertical?: ResponsiveSpacing
  padHorizontal?: ResponsiveSpacing

  // Setting styles via this prop
  // overwrites any of the potentially passed properties above.
  style?: StyleProp<ViewStyle>
}

export type BoxProps = BoxStyleProps & {
  children?: ReactNode
  onPress?: () => void
  onLayout?: (event: LayoutChangeEvent) => void
}

export function Box(props: BoxProps) {
  const { style, disabled, children, pointerEvents, onPress, onLayout } = props

  const sharedProps = {
    pointerEvents,

    // Allow overwriting the 'Box' style properties with custom ones.
    style: [useBoxStyle(props), style],

    onLayout,
  }

  // In case an 'onPress' handler is defined,
  // do render the Box via a 'PressableOpacity'.
  if (onPress) {
    return (
      <PressableOpacity {...sharedProps} disabled={disabled} onPress={onPress}>
        {children}
      </PressableOpacity>
    )
  }

  // Otherwise render a regular View
  // with passed styles and other properties.
  return <View {...sharedProps}>{children}</View>
}

export function useBoxStyle(props: BoxStyleProps): StyleProp<ViewStyle> {
  const theme = useTheme()
  const screenSize = useScreenSizeType()

  const style: ViewStyle = {}

  const retrievePixels = (spacing: ResponsiveSpacing) =>
    retrieveSpacingPixels(theme, screenSize, spacing)

  if (props.expand) style.flex = 1
  if (props.alignCenter) style.alignItems = "center"
  if (props.flex != undefined) style.flex = props.flex

  if (props.borderColor) {
    style.borderWidth = 1
    style.borderColor = props.borderColor
  }
  if (props.disabled == true) style.opacity = 0.35
  if (props.borderRadius)
    style.borderRadius = theme.borderRadius[props.borderRadius]
  if (props.elevation) {
    style.shadowOpacity = 0.2
    style.shadowColor = "black"
    style.elevation = props.elevation
    style.shadowRadius = props.elevation
    style.shadowOffset = { width: 0, height: props.elevation / 2 }
  }
  if (props.backgroundColor) style.backgroundColor = props.backgroundColor

  if (props.pad) style.padding = retrievePixels(props.pad)
  if (props.padVertical)
    style.paddingVertical = retrievePixels(props.padVertical)
  if (props.padHorizontal)
    style.paddingHorizontal = retrievePixels(props.padHorizontal)
  if (props.padTop) style.paddingTop = retrievePixels(props.padTop)
  if (props.padLeft) style.paddingLeft = retrievePixels(props.padLeft)
  if (props.padRight) style.paddingRight = retrievePixels(props.padRight)
  if (props.padBottom) style.paddingBottom = retrievePixels(props.padBottom)

  return style
}

export function useSpacing(spacing: ResponsiveSpacing): number {
  return retrieveSpacingPixels(useTheme(), useScreenSizeType(), spacing)
}

export function retrieveSpacingPixels(
  theme: Theme,
  screenSize: ScreenSizeType,
  spacing: ResponsiveSpacing,
): number {
  if (typeof spacing == "string") return theme.spacing[spacing]
  else if (spacing.length == 2) {
    if (screenSize == "S" || screenSize == "M") return theme.spacing[spacing[0]]
    else return theme.spacing[spacing[1]]
  } else if (spacing.length == 3) {
    if (screenSize == "S") return theme.spacing[spacing[0]]
    else if (screenSize == "M") return theme.spacing[spacing[1]]
    else return theme.spacing[spacing[2]]
  } else throw Error("Invalid ResponsiveSpacing received")
}
