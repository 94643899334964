import { useRecoilCallback } from "recoil"

import {
  dateTimePickerConfigState,
  dateTimePickerVisibilityState,
} from "../providers/DateTimePickerProviders/InternalDateTimeContext"
import { DateTimePickerConfig } from "../types"

type InputOptions = Omit<DateTimePickerConfig, "type" | "onChange">

export function useDateTimePicker() {
  const pick = useDateTimePickerInternal()

  const pickTime = (config: InputOptions) => pick({ ...config, type: "time" })
  const pickDate = (config: InputOptions) => pick({ ...config, type: "date" })
  const pickDateOrTime = (config: InputOptions) =>
    pick({ ...config, type: "datetime" })

  return { pickTime, pickDate, pickDateOrTime }
}

function useDateTimePickerInternal() {
  return useRecoilCallback(
    ({ set }) =>
      async (config: Omit<DateTimePickerConfig, "onChange">) =>
        new Promise<Date | undefined>(resolve => {
          set(dateTimePickerConfigState, { ...config, onChange: resolve })
          set(dateTimePickerVisibilityState, true)
        }),
    [],
  )
}
