import { clamp, cloneDeep, difference, isArray, isEqual } from "lodash"
import React, { ReactNode, useRef, useState } from "react"
import { Keyboard, Platform, Pressable, StyleSheet } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { useScreenOrientation } from "@axtesys/hooks"
import {
  Box,
  ButtonBar,
  Card,
  Column,
  Gap,
  Icon,
  Label,
  MCIcon,
  MobileSearchBar,
  Overlay,
  Row,
  RowSeparator,
  ToolTipIcon,
} from "@axtesys/kassen-app-ui"
import {
  contains,
  createCustomContext,
  filterSearch,
  indexOf,
  isEmptyOrBlank,
} from "@axtesys/react-tools"

import { useGeneralTranslation } from "../../feature/Translation/hooks"
import { PerformanceList } from "../PerformanceList"

export { SelectionProvider, useSelection }

type SharedSelectionConfig<T> = {
  items: T[]
  displayMapping(item: T): ReactNode | string

  title?: string
  tooltip?: string
  itemKey?: keyof T
  preventCancel?: boolean
  filterSelector?: (item: T) => Array<string | undefined>
}
type SingleSelectionConfig<T> = {
  selection?: T
  confirmMode?: boolean
}
type MultiSelectionConfig<T> = { selection?: T[] }

type SingleSelectionModeConfig<T> = SharedSelectionConfig<T> &
  SingleSelectionConfig<T>
type MultiSelectionModeConfig<T> = SharedSelectionConfig<T> &
  MultiSelectionConfig<T>

function SelectionProvider({ children }: { children: ReactNode }) {
  return (
    <SelectionContextProvider>
      {children}
      <SelectionOverlay />
    </SelectionContextProvider>
  )
}

function SelectionOverlay() {
  const {
    searchString,
    currentSelectionConfig,
    setSearchString,
    onMultiSelection,
  } = useSelectionInternal()
  const { top } = useSafeAreaInsets()
  const orientation = useScreenOrientation()
  const { tGeneral } = useGeneralTranslation()

  if (!currentSelectionConfig) return null

  const { title, items, itemKey, tooltip, selection, filterSelector } =
    currentSelectionConfig

  const isWeb = Platform.OS == "web"
  const cardStyle = [
    styles.cardOuterSpacing,
    isWeb && styles.webCard,
    { marginTop: clamp(top, 24, Number.MAX_VALUE) },
    !isWeb && orientation == "landscape" && styles.tabletCard,
  ]

  const showFilter = filterSelector != undefined
  const itemsToShow = showFilter
    ? filterSearch(items, searchString, filterSelector)
    : items
  const itemsPresent = itemsToShow.length > 0

  const heading = title && <Label h4 text={title} />
  const headingComponent = isEmptyOrBlank(tooltip) ? (
    heading
  ) : (
    <Row alignCenter gap="XXS">
      {heading}
      <ToolTipIcon text={tooltip!} />
    </Row>
  )

  // In case the selection context is in multi selection mode,
  // we do want to display the aggregate selection button (select/deselect all).
  const titleComponent = !("confirmMode" in currentSelectionConfig) ? (
    <Row expand alignCenter spaceBetween>
      {headingComponent}
      {itemsPresent ? (
        <AggregateSelectionIcon
          selection={selection}
          itemsToShow={itemsToShow}
          onMultiSelection={onMultiSelection}
        />
      ) : (
        <Gap size="S" />
      )}
    </Row>
  ) : (
    headingComponent
  )

  const emptyListText = (
    <Column gap="XS">
      <RowSeparator />
      <Box padLeft="XS">
        <Label
          bold
          color="medium"
          text={tGeneral(
            !showFilter || items.length == 0
              ? "noSelectionItemsFound"
              : "noSelectionItemsForSearch",
          )}
        />
      </Box>
      <RowSeparator />
    </Column>
  )

  return (
    <Overlay zIndex={200} centerContent={Platform.OS == "web"}>
      <Card expand style={cardStyle}>
        <Column padTop="XS" gap="XS" padBottom={showFilter ? "none" : "S"}>
          {titleComponent}

          {showFilter && (
            <Box style={styles.itemContainer}>
              <MobileSearchBar
                value={searchString}
                placeholder={tGeneral("searchPlaceholder")}
                onChange={setSearchString}
              />
            </Box>
          )}
        </Column>

        <Column expand gap="XS">
          <PerformanceList
            showFooterSeparator
            showHeaderSeparator
            itemKey={itemKey}
            items={itemsToShow}
            itemDimension={ITEM_HEIGHT}
            renderItem={SelectionItem}
            renderListEmptyComponent={emptyListText}
          />

          <SelectionButtonBar />
        </Column>
      </Card>
    </Overlay>
  )
}

function AggregateSelectionIcon<T>({
  selection,
  itemsToShow,
  onMultiSelection,
}: {
  selection: T[]
  itemsToShow: T[]
  onMultiSelection: (item: T) => void
}) {
  const isFullSelection = difference(itemsToShow, selection).length == 0

  const onPress = () => {
    const itemsToSelectionChange = !isFullSelection
      ? itemsToShow.filter(item => !selection.includes(item))
      : itemsToShow

    for (const item of itemsToSelectionChange) onMultiSelection(item)
  }

  return (
    <Icon
      color="primary"
      name={isFullSelection ? "collapse-all" : "expand-all"}
      onPress={onPress}
    />
  )
}

function SelectionItem<T>(item: T) {
  const { currentSelectionConfig } = useSelectionInternal()

  return "confirmMode" in currentSelectionConfig! ? (
    <SingleSelectionItem item={item} />
  ) : (
    <MultiSelectionItem item={item} />
  )
}

function SharedSelectionItem<T>({
  item,
  icon,
  onSelection,
}: {
  item: T
  icon: MCIcon
  onSelection: (item: T) => void
}) {
  const { currentSelectionConfig } = useSelectionInternal()

  const displayValue = currentSelectionConfig!.displayMapping(item)

  const onPress = () => onSelection(item)

  return (
    <Pressable onPress={onPress}>
      <Row
        alignCenter
        gap="XXS"
        padHorizontal="XXXS"
        style={styles.itemContainer}
      >
        <Icon name={icon} />
        {typeof displayValue == "string" ? (
          <Label text={displayValue} />
        ) : (
          displayValue
        )}
      </Row>
    </Pressable>
  )
}

function SingleSelectionItem<T>({ item }: { item: T }) {
  const { currentSelectionConfig, onSingleSelection } = useSelectionInternal()

  const selectionConfig =
    currentSelectionConfig! as SingleSelectionModeConfig<T>
  const isSelected =
    selectionConfig.selection && isEqual(selectionConfig.selection, item)
  const icon = isSelected
    ? "checkbox-marked-circle-outline"
    : "checkbox-blank-circle-outline"

  return (
    <SharedSelectionItem
      item={item}
      icon={icon}
      onSelection={onSingleSelection}
    />
  )
}

function MultiSelectionItem<T>({ item }: { item: T }) {
  const { currentSelectionConfig, onMultiSelection } = useSelectionInternal()

  const selectionConfig = currentSelectionConfig! as MultiSelectionModeConfig<T>
  const isSelected = contains(selectionConfig.selection, item)
  const icon = isSelected ? "checkbox-marked-outline" : "checkbox-blank-outline"

  return (
    <SharedSelectionItem
      item={item}
      icon={icon}
      onSelection={onMultiSelection}
    />
  )
}

function SelectionButtonBar() {
  const { tGeneral } = useGeneralTranslation()
  const { currentSelectionConfig, onResolveItemSelection, onCancelSelection } =
    useSelectionInternal()

  const selectionConfig = currentSelectionConfig!
  const isSubmitButtonVisible =
    !("confirmMode" in selectionConfig) || selectionConfig.confirmMode

  const actionButtons = isSubmitButtonVisible
    ? [
        {
          label: tGeneral("confirm"),
          onPress: () => onResolveItemSelection(selectionConfig.selection),
          disabled: isSubmitButtonVisible && !currentSelectionConfig?.selection,
        },
      ]
    : undefined

  const cancelButton =
    selectionConfig.preventCancel != true
      ? { label: tGeneral("close"), onPress: onCancelSelection }
      : undefined

  return (
    <ButtonBar
      cancelButton={cancelButton}
      actionButtons={actionButtons}
      contentContainer={{ padLeft: "S", padBottom: "XS" }}
    />
  )
}

function useSelection() {
  const { selectSingle, selectMulti, selectionInProgress } =
    useSelectionInternal()

  return { selectionInProgress, selectSingle, selectMulti }
}

const [SelectionContextProvider, useSelectionInternal] = createCustomContext(
  () => {
    const initialValueRef = useRef<any | any[] | undefined>()
    const [searchString, setSearchString] = useState<string>("")
    const [currentSelectionConfig, setCurrentSelectionConfig] = useState<
      | (SingleSelectionModeConfig<any> & { resolve?(item: any): void })
      | (MultiSelectionModeConfig<any> & { resolve?(items: any[]): void })
      | undefined
    >(undefined)

    const selectionInProgress = currentSelectionConfig != undefined

    // Always hide an open keyboard when
    // displaying the selection component.
    function hideKeyboard() {
      Keyboard.dismiss()
    }

    function resetSelection() {
      setSearchString("")
      setCurrentSelectionConfig(undefined)
    }

    async function selectSingle<T>(
      config: SingleSelectionModeConfig<T>,
    ): Promise<T> {
      hideKeyboard()
      initialValueRef.current = cloneDeep(config.selection)

      return new Promise<T>(resolve => {
        setCurrentSelectionConfig({
          confirmMode: config.confirmMode,
          ...config,
          resolve,
        })
      })
    }

    async function selectMulti<T>(
      config: MultiSelectionModeConfig<T>,
    ): Promise<T[]> {
      hideKeyboard()
      initialValueRef.current = cloneDeep(config.selection)

      return new Promise<T[]>(resolve => {
        setCurrentSelectionConfig({ ...config, resolve })
      })
    }

    function onSingleSelection<T>(item: T) {
      setCurrentSelectionConfig(
        prevState =>
          ({ ...prevState, selection: item }) as SingleSelectionModeConfig<T>,
      )
      if (
        currentSelectionConfig &&
        "confirmMode" in currentSelectionConfig &&
        !currentSelectionConfig.confirmMode
      ) {
        onResolveItemSelection(item)
      }
    }

    function onMultiSelection<T>(item: T) {
      setCurrentSelectionConfig(prevState => {
        const selection = (prevState?.selection ?? []) as T[]

        if (!contains(selection, item)) {
          selection.push(item)
        } else {
          const index = indexOf(selection, item)
          if (index > -1) selection.splice(index, 1)
        }

        return { ...prevState, selection } as MultiSelectionModeConfig<T>
      })
    }

    function onResolveItemSelection<T>(selection: T | T[]) {
      if (!currentSelectionConfig || !currentSelectionConfig.resolve) return

      if ("confirmMode" in currentSelectionConfig)
        currentSelectionConfig.resolve(selection)
      else if (isArray(selection)) currentSelectionConfig.resolve(selection)

      resetSelection()
    }

    const onCancelSelection = () => {
      if (currentSelectionConfig?.preventCancel == true) return

      if (
        currentSelectionConfig &&
        currentSelectionConfig.resolve &&
        initialValueRef.current
      ) {
        currentSelectionConfig.resolve(initialValueRef.current)
      }

      resetSelection()
    }

    return {
      searchString,
      selectionInProgress,
      currentSelectionConfig,

      selectMulti,
      selectSingle,
      setSearchString,
      onMultiSelection,
      onSingleSelection,
      onCancelSelection,
      onResolveItemSelection,
    } as const
  },
)

const ITEM_HEIGHT = 48

const styles = StyleSheet.create({
  webCard: {
    width: "30%",
    minWidth: 350,
    maxHeight: "80%",
    alignSelf: "center",
  },
  itemContainer: { height: ITEM_HEIGHT },
  tabletCard: { width: 400, alignSelf: "center" },
  cardOuterSpacing: { marginBottom: 24, marginHorizontal: 16 },
})
