import Big from "big.js"

import { arrayEquals } from "@axtesys/react-tools"

import { DiscountType } from "~shared/api/graphql/types"
import { discountEquals } from "~shared/lib/Discount"
import {
  CompleteDiscount,
  Discount,
  InvoiceItem,
  OutputInvoiceItem,
} from "~shared/types"

import {
  DiscountWithTypes,
  InvoiceItemConfiguration,
  PreOutputInvoiceItem,
} from "./types"

// Transforms the less specified PreOutputInvoiceItem
// to an OutputInvoiceItem including
// the calculation of taxInfo, discounts and
// item's aggregated taxInfo (gross, net, discount, tax).
export function evaluateInvoiceItem(
  item: PreOutputInvoiceItem,
): OutputInvoiceItem {
  const {
    amount,
    article: { price, taxRate },
  } = item

  const { gross, evaluatedDiscounts, isItemDiscounted } =
    evaluateGrossPositionTotal(item)

  const undiscountedGross = price.times(amount).round(2)
  const discount = isItemDiscounted ? undiscountedGross.minus(gross) : Big(0)
  const net = gross
    .times(100.0)
    .div(100.0 + taxRate)
    .round(2)
  const tax = gross.minus(net)

  const discountedPricePerUnit = gross.div(amount).round(2)

  return {
    ...item,
    discountedPricePerUnit,
    discounts: evaluatedDiscounts,
    taxInfo: { gross, net, tax, discount, taxRate, undiscountedGross },
  }
}

export function evaluateGrossPositionTotal({
  amount,
  discounts,
  article: { price },
}: PreOutputInvoiceItem) {
  const undiscountedPositionTotal = price.times(amount)

  let remainingPositionTotal = undiscountedPositionTotal
  const evaluatedDiscounts: CompleteDiscount[] = []

  for (const discount of discounts) {
    let evaluatedDiscount: CompleteDiscount | undefined

    if (discount.absolute) {
      const percent = discount.absolute
        .times(amount)
        .div(!remainingPositionTotal.eq(0) ? remainingPositionTotal : Big(1.0))
        .times(100.0)

      evaluatedDiscount = {
        percent,
        initialType: "Absolute",
        name: discount.name ?? "",
        absolute: discount.absolute,
        discountType: discount.discountType,
      }
    } else if (discount.percent) {
      const absolute = remainingPositionTotal
        .minus(
          calculateDiscountedPrice(
            remainingPositionTotal,
            discount,
            "no-round",
          ),
        )
        .div(!amount.eq(0) ? amount : Big(1))

      evaluatedDiscount = {
        absolute,

        // As all cart discounts are being transformed to a percent value,
        // it is required to pass on the initialType in `transformCartDiscounts`.
        // Without this procedure, the initialType of a cart discount
        // will always be "Percent" and therefore inaccurate at the end.
        initialType: discount.initialType ?? "Percent",

        name: discount.name ?? "",
        percent: discount.percent,
        discountType: discount.discountType,
      }
    } else throw Error("Undefined discount")

    remainingPositionTotal = remainingPositionTotal
      .minus(evaluatedDiscount.absolute.times(amount))
      .round(2)

    evaluatedDiscounts.push(evaluatedDiscount)
  }

  const isItemDiscounted =
    evaluatedDiscounts.length > 0 &&
    !remainingPositionTotal.eq(undiscountedPositionTotal)

  return {
    isItemDiscounted,
    evaluatedDiscounts,
    gross: remainingPositionTotal.round(2),
  }
}

export function evaluateInvoiceItems(
  items: InvoiceItem[],
  cartDiscount?: DiscountWithTypes,
): OutputInvoiceItem[] {
  return items.map(item => {
    // Transform all item discounts
    let discounts = transformDiscountsToDiscountsWithType(
      item.discounts,
      "ItemDiscount",
    )

    // If there is no cart discount just calculate
    // the taxInfo and the discounted price of the individual item.
    if (!cartDiscount) return evaluateInvoiceItem({ ...item, discounts })

    if (
      !item.isDiscountBlocked &&
      item.amount.gt(0) &&
      item.article.price.gt(0)
    ) {
      // Merge the possible individual discounts
      // with the calculated cart discounts

      const transformedCartDiscount = transformDiscountsToDiscountsWithType(
        [cartDiscount],
        "CartDiscount",
      )

      discounts = [...discounts, ...transformedCartDiscount]
    }

    return evaluateInvoiceItem({ ...item, discounts })
  })
}

export function calculateDiscountedPrice(
  price: Big,
  discount: Discount,
  round: "round" | "no-round",
) {
  let discountedPrice = price

  if (discount.absolute)
    discountedPrice = discountedPrice.sub(discount.absolute)
  else if (discount.percent)
    discountedPrice = discountedPrice.mul(
      Big(1).minus(discount.percent.div(100)),
    )

  if (round == "round") discountedPrice = discountedPrice.round(2)

  return discountedPrice
}

// Calculate cart discount percentage
// from the total of discountable items,
// preserve / set the initial type of the cart discount
// and return it without an absolute value.
export function transformToCartDiscount(
  discountablePriceTotal: Big,
  cartDiscount?: Discount,
): DiscountWithTypes | undefined {
  return cartDiscount?.absolute
    ? {
        name: cartDiscount.name,
        initialType: "Absolute",
        percent: cartDiscount.absolute
          .div(
            discountablePriceTotal.eq(0.0) ? Big(1.0) : discountablePriceTotal,
          )
          .times(100.0),
      }
    : cartDiscount
}

// Transforms the default `Discount` to an output `DiscountWithType`
export function transformDiscountsToDiscountsWithType(
  discounts: Discount[],
  discountType: DiscountType,
) {
  return discounts.map(discount => ({
    ...discount,
    discountType,
  }))
}

export function checkForInvoiceItemConfigurationEquality(
  a: InvoiceItemConfiguration,
  b: InvoiceItemConfiguration,
) {
  return (
    a.article.articleId == b.article.articleId &&
    a.article.price.eq(b.article.price) &&
    a.additionalText == b.additionalText &&
    arrayEquals(a.discounts, b.discounts, discountEquals) &&
    a.isNegativeAmount == b.isNegativeAmount
  )
}
