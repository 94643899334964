import { useCallback, useEffect, useState } from "react"

import { UseFormValue } from "@axtesys/hooks"

import { MutationVariables, useGraphQL } from "~shared/api/graphql"

import { CompanyId } from "../../types/Company"
import { useScreensTranslation } from "../Translation/hooks"
import { useLogin, useLogoutAndFail } from "./hooks"
import { LoginFormData, LoginFormDataValue } from "./types"

export function useRegisterCompanyWithManager(
  navigateToLogin: (managerEmail: string) => void,
) {
  const { sendMutation } = useGraphQL()
  const { tScreen } = useScreensTranslation("RegistrationScreen")

  return useCallback(
    async (
      variables: MutationVariables<"RegisterCompanyWithManager">,
      form: UseFormValue<{
        companyName: string
        email: string
        taxId: string
        password: string
        repeatedPassword: string
      }>,
    ) => {
      await sendMutation("RegisterCompanyWithManager", {
        variables: { ...variables, taxId: variables.taxId.toUpperCase() },
        onSuccess: () => navigateToLogin(variables.managerEmail),
        snackMessage: () =>
          tScreen("successRegisterMessage", { name: variables.companyName }),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "CompanyNameExists":
              form.setErrorMessages({ companyName: errorMessage })
              break
            case "InvalidEmailAddress":
            case "ManagerEmailExists":
              form.setErrorMessages({ email: errorMessage })
              break
            case "TaxIdExists":
            case "InvalidTaxId":
              form.setErrorMessages({ taxId: errorMessage })
              break
            case "InvalidPassword":
              form.setErrorMessages({
                password: errorMessage,
                repeatedPassword: errorMessage,
              })
              break
            default:
              break
          }
        },
      })
    },
    [navigateToLogin, sendMutation, tScreen],
  )
}

export function useRequestResendActivation(isEmailNotConfirmed: boolean) {
  const { sendMutation } = useGraphQL()
  const { tScreen } = useScreensTranslation("RegistrationScreen")
  const [activationEmailResent, setActivationEmailResent] = useState(false)

  // In case the user already used the resend `LinkLabel`,
  // after registration and tries to login,
  // but is still not confirmed re-display the resend `LinkLabel`.
  useEffect(() => {
    if (isEmailNotConfirmed) setActivationEmailResent(false)
  }, [isEmailNotConfirmed])

  const resendActivation = useCallback(
    async (managerEmail: string, form: UseFormValue<LoginFormData>) => {
      await sendMutation("PublicResendActivationEmail", {
        variables: { managerEmail },
        onSuccess: () => {
          setActivationEmailResent(true)
          form.setErrorMessages({})
        },
        snackMessage: () =>
          tScreen("successResendActivationMessage", { email: managerEmail }),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "ManagerNotFound":
            case "AlreadyConfirmed":
              setActivationEmailResent(true)
              form.setErrorMessages({ email: errorMessage })
              break
            case "InvalidEmailAddress":
              form.setErrorMessages({ email: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [sendMutation, tScreen],
  )

  return { activationEmailResent, resendActivation }
}

export function useLoginAdmin() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()

  return useCallback(
    async (variables: LoginFormData, form: LoginFormDataValue) => {
      await sendMutation("LoginAdminAccount", {
        variables,
        excludeFromLogging: "password",
        onSuccess: ({ data }) => login(data.authentication.loginAdmin),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "InvalidEmailAddress":
              form.setErrorMessages({ email: errorMessage })
              break
            case "InvalidCredentials":
              form.setErrorMessages({ submit: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [login, sendMutation],
  )
}

export function useAssumeCompany() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()
  const logoutAndFail = useLogoutAndFail()

  return useCallback(
    async (companyId?: CompanyId) => {
      await sendMutation("AssumeCompany", {
        variables: { companyId },
        onSuccess: ({ data }) => login(data.authentication.assumeCompany),
        onError: logoutAndFail,
      })
    },
    [login, logoutAndFail, sendMutation],
  )
}

export function useLoginManager() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()

  return useCallback(
    async (variables: LoginFormData, form: LoginFormDataValue) => {
      await sendMutation("LoginManagerAccount", {
        variables,
        excludeFromLogging: "password",
        onSuccess: ({ data }) => login(data.authentication.loginManager),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "AccountLocked":
            case "InvalidEmailAddress":
            case "EmailNotConfirmed":
              form.setErrorMessages({ email: errorMessage })
              break
            case "InvalidCredentials":
              form.setErrorMessages({ submit: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [login, sendMutation],
  )
}
