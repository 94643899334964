import { Big } from "big.js"
import { TFunction } from "i18next"

import {
  DetailedReceiptFragment,
  ReceiptContactFragment,
  ReceiptFragment,
  ReceiptItemFragment,
  TaxInfoInput,
  TipTransferFragment,
  ValueTransferFragment,
} from "../../api/graphql/types"
import { toBig } from "../../lib/Big"
import { eurosToBig } from "../../lib/Money"
import {
  DisplayReceipt,
  DisplayTipTransfer,
  DisplayValueTransfer,
  GPtomReceiptData,
  ReceiptItem,
  TotalTaxInfo,
} from "../../types"
import { t_General } from "../Translation/lib"

export function calculateRoundingDifference(totalTaxInfo: TotalTaxInfo) {
  return totalTaxInfo.gross.minus(totalTaxInfo.correctedGross)
}

export function calculateGPtomAmount({ amount, tipAmount }: GPtomReceiptData) {
  return Big(amount ? amount + (tipAmount ?? 0) : -0)
}

const whiteSpacesRegex = /\s/g
const onlyNumbersRegex = /[^0-9]/g

export function transformCardNumber(cardNumber?: string): string | undefined {
  return cardNumber
    ?.replaceAll(whiteSpacesRegex, "")
    ?.replaceAll(onlyNumbersRegex, "*")
}

type TransferTranslationFunctions = {
  tGeneral: TFunction<"general">
  tScreen: TFunction<"screens", "ReceiptDetailsScreen">
}

export function transformTransfers(
  { tips, payouts, payments }: DisplayReceipt,
  { tScreen, tGeneral }: TransferTranslationFunctions,
) {
  const paymentRows = payments.map(payment => ({
    name: `${tScreen("given")} ${payment.methodName}`,
    amount: payment.amount,
  }))

  const tipsRows = tips
    ? tips.map(tip => ({
        name: `${tGeneral("tip")} ${tip.originateFrom.methodName}`,
        amount: tip.amount,
      }))
    : []

  const payoutRows = payouts
    ? payouts.map(payout => ({
        name: `${tScreen("moneyAmountReturned")} ${payout.methodName}`,
        amount: payout.amount.times(-1),
      }))
    : []

  return [...paymentRows, ...tipsRows, ...payoutRows]
}

// GraphQL to frontend transformation functions

export function transformFromGraphQLToDisplayReceipt(
  receipt: ReceiptFragment | DetailedReceiptFragment,
): DisplayReceipt {
  return {
    receiptId: receipt.id,
    createdAt: receipt.createdAt,
    company: receipt.initialCompany,
    receiptType: receipt.receiptType,
    operator: receipt.initialOperator,
    receiptNumber: receipt.receiptNumber,
    qrCodeRepresentation: receipt.signature,
    cashRegister: receipt.initialCashRegister,
    hobexReceiptData: receipt.hobexReceiptData,
    gpTomReceiptData: receipt.gpTomReceiptData,
    signatureUrlHash: receipt.signatureUrlHash,
    refundsReceiptNumber: receipt.refunds?.receiptNumber,
    items: receipt.items.map(transformFromGraphQLToReceiptItem),
    refundedByReceiptNumber: receipt.refundedBy?.receiptNumber,
    cancelledByReceiptNumber: receipt.cancelledBy?.receiptNumber,
    totalTaxInfo: {
      correctedGross: eurosToBig(
        receipt.totalTaxInfo.correctedGross ?? receipt.totalTaxInfo.gross,
      ),
      ...transformFromGraphQLToTaxInfo(receipt.totalTaxInfo),
    },
    receivedAs: receipt.receivedAs,
    taxInfos: receipt.taxInfos.map(taxInfo => ({
      taxRate: taxInfo.taxRate,
      ...transformFromGraphQLToTaxInfo(taxInfo),
    })),
    payouts: receipt.payouts.map(transformFromGraphQLToDisplayValueTransfer),
    payments: receipt.payments.map(transformFromGraphQLToDisplayValueTransfer),
    tips: receipt.tips.map(transformFromGraphQLToDisplayTipTransfer),
    signatureDeviceFailed: receipt.signatureDeviceFailed ?? true,
    ...transformFromGraphQLToContact(receipt),
  }
}

export function transformFromGraphQLToSharedReceiptItem(
  item: ReceiptItemFragment,
) {
  return {
    invoiceItemId: item.id,
    amount: toBig(item.amount)!,
    isNegativeAmount: item.amount < 0,
    additionalText: item.additionalText,
    discountNames: item.discountNames.map(
      name => name ?? t_General().tGeneral("discount"),
    ),
    taxInfo: {
      taxRate: item.initialArticle.taxRate,
      ...transformFromGraphQLToTaxInfo(item.taxInfo),
    },
  }
}

function transformFromGraphQLToReceiptItem(
  item: ReceiptItemFragment,
): ReceiptItem {
  return {
    article: {
      name: item.initialArticle.name,
      taxRate: item.initialArticle.taxRate,
      price: eurosToBig(item.initialArticle.price),
    },
    ...transformFromGraphQLToSharedReceiptItem(item),
  }
}

function transformFromGraphQLToDisplayValueTransfer(
  transfer: ValueTransferFragment,
): DisplayValueTransfer {
  return {
    paymentMethodId: transfer.paymentMethodId,
    methodName: transfer.paymentMethodName,
    amount: eurosToBig(transfer.moneyAmount),
  }
}

function transformFromGraphQLToDisplayTipTransfer(
  transfer: TipTransferFragment,
): DisplayTipTransfer {
  return {
    originateFrom: { methodName: transfer.originateFrom.paymentMethodName },
    amount: eurosToBig(transfer.moneyAmount),
  }
}

function transformFromGraphQLToTaxInfo(taxInfo: Omit<TaxInfoInput, "taxRate">) {
  return {
    net: eurosToBig(taxInfo.net),
    tax: eurosToBig(taxInfo.tax),
    gross: eurosToBig(taxInfo.gross),
    discount: eurosToBig(taxInfo.discount),
    undiscountedGross: eurosToBig(taxInfo.undiscountedGross),
  }
}

function transformFromGraphQLToContact(contact: ReceiptContactFragment) {
  return {
    receiptText: contact.receiptText,
    department: contact.initialDepartment,
    billingAddress: contact.initialBillingAddress,
    shippingAddress: contact.initialShippingAddress,
  }
}
