import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { Salutation } from "../api/graphql/types"

export type ShippingAddressId = string

export type ShippingAddress = {
  shippingAddressId: ShippingAddressId
  salutation: Salutation
  lastName: string
  city: string
  street: string
  streetNumber: string
  zipCode: string
  isDefault: boolean

  firstName?: string
  countryCode?: string
}

export type ShippingAddressData = Omit<ShippingAddress, "isDefault">

export const ShippingAddressEntity = createEntityFor<ShippingAddress>()
  .withSchema(
    z.object({
      shippingAddressId: z.string(),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      lastName: z.string(),
      city: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      zipCode: z.string(),
      isDefault: z.boolean(),
      firstName: z.ostring(),
      countryCode: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const ShippingAddressDataEntity = createEntityFor<ShippingAddressData>()
  .withSchema(
    z.object({
      shippingAddressId: z.string(),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      lastName: z.string(),
      city: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      zipCode: z.string(),
      firstName: z.ostring(),
      countryCode: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
