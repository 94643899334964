import React from "react"
import { View } from "react-native"

import { GapOptions, useGapping } from "../hooks/useGapping"
import { Row, RowProps } from "./FlexBox"

type WrappingRowProps = RowProps & GapOptions

export function WrappingRow({ style, children, ...props }: WrappingRowProps) {
  return (
    <Row style={[{ ...useGapping(props), flexWrap: "wrap" }, style]} {...props}>
      {children}
    </Row>
  )
}

// Useful in case of a more complex layout,
// which potentially overwrites a present bottom padding.
//
// By adding this pseudo-element
// at the end of a WrappingRow's content (gap or rowGap must be defined)
// it makes sure that there is indeed a gap at the bottom.
export function WrappingGapElement() {
  return <View style={{ width: "100%", height: 0.1, marginTop: -0.1 }} />
}
