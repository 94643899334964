"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileAsyncTransport = void 0;
let EXPOqueue = [];
let EXPOelaborate = false;
const EXPOFSreadwrite = () => __awaiter(void 0, void 0, void 0, function* () {
    EXPOelaborate = true;
    const item = EXPOqueue[0];
    const prevFile = yield item.FS.readAsStringAsync(item.file);
    const newMsg = prevFile + item.msg;
    yield item.FS.writeAsStringAsync(item.file, newMsg);
    EXPOelaborate = false;
    EXPOqueue.shift();
    if (EXPOqueue.length > 0) {
        yield EXPOFSreadwrite();
    }
});
const EXPOcheckqueue = (FS, file, msg) => __awaiter(void 0, void 0, void 0, function* () {
    EXPOqueue.push({ FS, file, msg });
    if (!EXPOelaborate) {
        yield EXPOFSreadwrite();
    }
});
const EXPOFSappend = (FS, file, msg) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fileInfo = yield FS.getInfoAsync(file);
        if (!fileInfo.exists) {
            yield FS.writeAsStringAsync(file, msg);
            return true;
        }
        else {
            yield EXPOcheckqueue(FS, file, msg);
            return true;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }
});
const RNFSappend = (FS, file, msg) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield FS.appendFile(file, msg, "utf8");
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
});
const fileAsyncTransport = (props) => {
    var _a, _b, _c;
    if (!props)
        return false;
    let WRITE;
    let fileName = "log";
    let filePath;
    if (!((_a = props === null || props === void 0 ? void 0 : props.options) === null || _a === void 0 ? void 0 : _a.FS)) {
        throw Error(`react-native-logs: fileAsyncTransport - No FileSystem instance provided`);
    }
    if (props.options.FS.DocumentDirectoryPath && props.options.FS.appendFile) {
        WRITE = RNFSappend;
        filePath = props.options.FS.DocumentDirectoryPath;
    }
    else if (props.options.FS.documentDirectory &&
        props.options.FS.writeAsStringAsync &&
        props.options.FS.readAsStringAsync &&
        props.options.FS.getInfoAsync) {
        WRITE = EXPOFSappend;
        filePath = props.options.FS.documentDirectory;
    }
    else {
        throw Error(`react-native-logs: fileAsyncTransport - FileSystem not supported`);
    }
    if ((_b = props === null || props === void 0 ? void 0 : props.options) === null || _b === void 0 ? void 0 : _b.fileName) {
        let today = new Date();
        let d = today.getDate();
        let m = today.getMonth() + 1;
        let y = today.getFullYear();
        fileName = props.options.fileName;
        fileName = fileName.replace("{date-today}", `${d}-${m}-${y}`);
    }
    if ((_c = props === null || props === void 0 ? void 0 : props.options) === null || _c === void 0 ? void 0 : _c.filePath)
        filePath = props.options.filePath;
    let output = `${props === null || props === void 0 ? void 0 : props.msg}\n`;
    var path = filePath + "/" + fileName;
    WRITE(props.options.FS, path, output);
};
exports.fileAsyncTransport = fileAsyncTransport;
