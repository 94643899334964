import { ValueTransferFlagsInput } from "~shared/api/graphql/types"
import { PaymentMethod } from "~shared/types/PaymentMethod"

export function transformPaymentMethodToValueTransferFlags(
  paymentMethod: PaymentMethod,
): ValueTransferFlagsInput {
  return {
    overpayAllowed: paymentMethod.properties.overpayAllowed,
    usableForPayout: paymentMethod.properties.usableForPayout,
    affectsCashTransfers:
      paymentMethod.programmaticProperties.affectsCashTransfers,
  }
}
